import React from 'react'
import './mobile-footer.scss';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';

const MobileFooter = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 957);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 957);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='mobile-footer'>
            {isMobile ?
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Home" icon={<span className="material-symbols-outlined">home</span>} />
                        <BottomNavigationAction onClick={() => { window.location.href = '/' }} label="Stores" icon={<span className="material-symbols-outlined">storefront</span>} />
                        <BottomNavigationAction onClick={() => { window.location.href = '/search' }} label="Search" icon={<span className="material-symbols-outlined">search</span>} />
                        <BottomNavigationAction label="Profile" icon={<span className="material-symbols-outlined">person</span>} />
                    </BottomNavigation>
                </Paper> :
                null
            }
        </div>
    )
}

export default MobileFooter;