import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import OtpField from '../../components/otp/otp';
import { LocalService, PublicService } from '../../core/services';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { FormValidation } from '../../core/utility';
import './login.scss';
import { connect } from 'react-redux';
import { setUserVerified } from '../../redux/action';
import { useNavigate } from 'react-router'; 

const localService: LocalService = new LocalService();
const publicService: PublicService = new PublicService();
const clanShopService: ClanShopService = new ClanShopService();
const formValidation: FormValidation = new FormValidation();

interface ErrorTypes {
    status: boolean;
    message: string;
}

interface Props {
    setUserVerified: (isVerified: boolean, userDetails?: any) => void;
}


type Error = {
    error_whatsapp_number: ErrorTypes,
    error_email: ErrorTypes
}

const Login: React.FC<Props> = ({ setUserVerified }) => {

    const [showotpBox, setOtpBox] = useState(false);
    const [disableWhatsappfield, setDisableWhatsAppField] = useState(false);
    const [disableSendOtp, setDisableSendOtp] = useState(true);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        whatsapp_number: '',
        email: ''
    });
    const [error, setError] = useState<Error>({
        error_whatsapp_number: { status: false, message: '' },
        error_email: { status: false, message: '' }
    });


    const updateUserVerified = (newState: any, userData: any) => {
        if (userData.length > 0) {
            setUserVerified(newState, (userData[0]?.id ? userData[0]?.id:userData[0]?.user_id))
            navigate('/delivery-details');
        } else {

        }
        // setUserData(userData)
    };


    const handleSendOtpButton = async () => {
        try {
            const geMobiletError = formValidation.GetMobileControlIsValid(`${formData.whatsapp_number}`);
            const emailError = formValidation.GetEmailControlIsValid(formData.email);
            setError({ error_whatsapp_number: geMobiletError, error_email: emailError });

            // console.log('dfghjkk =>>> ', error.error_email, error.error_whatsapp_number)
            if (geMobiletError.status && emailError.status) {
                const response = await publicService.clanShopSendOtp({ mobile: +formData.whatsapp_number });
                // console.log('response', response);
                if (response?.data?.rows?.status) {
                    setOtpBox(response?.data?.rows?.status);
                    // setDisableWhatsAppField(response?.data?.rows?.status);
                    toast.success(response?.data?.rows?.message);
                } else {
                    toast.error(response?.data?.rows?.message);
                }
            }
        } catch (err) {
            console.log('handleSendOtpButton', err);
        }
    };

    const handleWhatsApp = (e: any) => {
        setFormData({ ...formData, whatsapp_number: e.target.value });
        if (e.target.value.length === 10) {
            setDisableSendOtp(false);
        } else {
            setDisableSendOtp(true);
        }
    };

    const handleWhatsAppOtp = async (otp: any) => {
        try {
            if (otp.length === 4) {
                const response = await publicService.verifyOtp({ mobile: +formData.whatsapp_number, otp: otp });
                // console.log('userResponse', response);
                if (response?.data?.rows?.status) {
                    setOtpBox(false);
                    setDisableWhatsAppField(true);
                    toast.success(response?.data?.rows?.message);
                    const userResponse = await clanShopService.checkUserExsist(+formData.whatsapp_number);
                    // console.log('userResponse', userResponse);
                    if (userResponse?.data?.rows.length > 0) {
                        
                        updateUserVerified(true, userResponse?.data?.rows);
                    } else {
                        // setUserExsist(true);s
                        const addGuestUser = await clanShopService.createGuestUser({whatsapp_number:formData.whatsapp_number, email_id:formData.email, mobile_verified:'Yes', email_verified:'No', name:'Guest User'});
                        // console.log(addGuestUser);
                        if(addGuestUser?.data?.status?.status){
                            updateUserVerified(true, addGuestUser?.data?.rows);
                        }
                    }
                } else {
                    toast.error(response?.data?.rows?.message);
                }
            }


        } catch (err) {
            console.log('VerifyOtp Error ::: ' + error)
            // throw new Error('VerifyOtp Error ::: ' + error);
        }

    };

    const handleEmail = (e: any) => {

        setFormData({ ...formData, email: e.target.value });
    };

    return (
        <div className='container'>
            <div className='login-container'>
            <div className='user-verification-container'>
                <div className='col-lg-12 '>
                    <div className='__wa_column clanshop_wa'>
                        <TextField
                            className='w-100 input-style'
                            type='number'
                            size='small'
                            color='primary'
                            InputProps={{
                                readOnly: disableWhatsappfield,
                            }}
                            // onBlur={}
                            name='whatsapp_number'
                            value={formData.whatsapp_number}
                            onChange={handleWhatsApp}
                            // (e) =>
                            //     e.target.value.length <= 10
                            //         ? setFormData({...formData, whatsapp_number:
                            //               e.target.value
                            //         })
                            //         : formData.whatsapp_number
                            // }
                            id='whatsapp_number_id'
                            label='WhatsApp No.'
                            variant='outlined'
                        />
                        <span className='error'>
                            {error?.error_whatsapp_number?.message}
                        </span>

                        {/* {!dynamicOtp && <span className='error'>Please Verify Mobile No. *</span>} */}
                        {/* {showotpBox && <span className='countdown'>{countDown}</span>} */}
                    </div>
                </div>


                <div className='col-lg-12 mt-3'>
                    <div className='clanshop_wa'>
                        <TextField
                            size='small'
                            fullWidth
                            className='influencer_personal_info'
                            name='email_id'
                            type='text'
                            id='outlined-basic'
                            label='Email'
                            value={formData.email}
                            onChange={handleEmail}
                            variant='outlined'
                        />
                        <span className='error'>
                            {error.error_email?.message}
                        </span>
                        {/* <Button
                                            variant='contained'
                                            onClick={handleSendEmailOtpButton}
                                            className='__button __button-influender clanshopwa_send_otp'
                                            color='success'
                                            startIcon={<EmailIcon />}
                                        >
                                            {' '}
                                            Send OTP{' '}
                                        </Button> */}
                        {/* {!dynamicOtp && <span className='error'>Please Verify Mobile No. *</span>} */}
                        {/* {showotpBox && <span className='countdown'>{countDown}</span>} */}
                    </div>
                </div>
                <Button
                    variant='contained'
                    onClick={handleSendOtpButton}
                    disabled={disableSendOtp}
                    className='__button __button-influender clanshopwa_send_otp'
                    color='success'
                    startIcon={<WhatsAppIcon />}
                >
                    {' '}
                    Send OTP{' '}
                </Button>


                {showotpBox && (
                    <OtpField handleOtp={handleWhatsAppOtp} />
                )}

                {/* <div className='input'>
                    <TextField className='mb-3' id="outlined-basic" label="Phone number" variant="outlined" size='small'
                    />
                    <span className='error'>

                    </span>
                    <button className='btn btn-primary'
                        onClick={handleSendOtpButton}
                    //     disabled={disableWhatsappfield}
                    >Sent OTP</button>
                    {showotpBox && (
                        <div className='otp-verify'>
                            <span className='title'>An OTP is sent to +91 9743585883</span>
                            <OtpField
                                handleOtp={handleWhatsAppOtp}
                            />
                            <button className='btn btn-primary'
                            onClick={handleVerifyOtpButton}
                            >Verify</button>
                            <p className='change-phone'>Change phone number</p>
                        </div>

                    )}
                </div> */}
            </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        CartReducer: state.CartReducer.items,
        isVerified: state.authReducer.isVerified,
        userToken: state.authReducer.userToken,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setUserVerified: (isVerified: boolean, userToken: number) => dispatch(setUserVerified(isVerified, userToken))
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

export { connectToRedux as Login };