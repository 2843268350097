import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import { ResponseType, IsApproved, GenerateInvoice, BrandGetInvoice } from "custom-type";

const http = new HttpService();

export class InvoiceService {
  //----------------------------------------- Influencer Invoice ---------------------------------------------//

  async allowToGenrateInvoice(data: IsApproved): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/approved/creative";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async generateInvoice(data: GenerateInvoice): Promise<ResponseType> {
    const path =
      BASE_URL + "/influencer/campaign/includes/paid/invoice/generate";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async getGeneratedInvoice(data: any): Promise<ResponseType> {
    const path = BASE_URL + `/influencer/get/invoice?id=${data}`;
    try {
      const response:any = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  //----------------------------------------- Brand Invoice ---------------------------------------------//

  async brandGetInvoices(data: BrandGetInvoice): Promise<ResponseType> {
    const path = BASE_URL + `/brand/get/invoices`;
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
}
