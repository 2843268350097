import './product-details.scss';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import StraightenIcon from '@mui/icons-material/Straighten';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { useState, useEffect } from 'react';
import {
    useLocation
} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ProductSlider } from '../../components/clan-shop/product-slider';
import { ProductContent } from '../../components/clan-shop/product-content';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Radio } from '@mui/material';
import { addToCart } from '../../redux/action';

interface Props {
    // item: any;
    addToCart: (item: any) => void;
    CartReducer: any;
}
const sizes = ['XS', 'S', 'M', 'L', 'XL'];
const clanshopService: ClanShopService = new ClanShopService();

const SizeButton = ({ size, selectedSize, onClick }: any) => (
    <Button
        variant={selectedSize === size ? "contained" : "outlined"}
        onClick={() => onClick(size)}
    >
        {size}
    </Button>
);

const generateInstagramEmbedCode = (url: any) => {
    // console.log(url);
    const shortcode = url.match(/\/(p|reel|tv)\/([^/]+)/)
    const postId = shortcode[2]; // Extract the post ID from the URL
    return `<iframe class='iframe-cont'src="https://www.instagram.com/${shortcode[1]}/${postId}/embed/captioned" width="400" height="500" frameborder="0" scrolling="no" allowfullscreen="true" allow="autoplay; encrypted-media"></iframe>`;
};

const InstagramEmbed: React.FC<{ embedCode: string }> = ({ embedCode }) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: embedCode }} />
    );
};
const ProductDetails: React.FC<Props> = ({ addToCart, CartReducer
}) => {

    const [shopDetails, setShopDetails] = useState<any>([]);
    const [ProductDetails, setProductDetails] = useState<any>(null);
    const [shopProducts, setShopProducts] = useState<any>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [selectedSize, setSelectedSize] = useState('');
    const [cartButton, setCartButton] = useState('Add to Cart')
    const [chooseSize, setChooseSize] = useState('')
    const [influencerMedia, setInfluencerMedia] = useState<any>([]);
    const [selectedColor, setSelectedColor] = useState('')



    let shop_id = queryParams.get('shop_id');
    let product_id = queryParams.get('product_id');
    const handleButtonClick= (size: string) => {
        // console.log('handleButtonClick',size)
        setSelectedSize(size);
        const found = CartReducer.items.some((product: any) => product.product_id === ProductDetails[0]?.product_id && product.size === size && product.shop_id === shop_id);
        if (found) {
            setCartButton('Go to Cart');
            //   setSelectedSize(selectedSize);
        } else {
            setCartButton('Add to Cart')
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const shopDetailsRes = await clanshopService.getShopDetails(shop_id);
                if (shopDetailsRes?.data?.status === 1) {
                    setShopDetails(shopDetailsRes?.data?.rows);
                }

                const shopProductsRes = await clanshopService.getShopProducts(shop_id);
                if (shopProductsRes?.data?.status === 1) {
                    setShopProducts(shopProductsRes?.data?.rows);
                }

                const productDetailsRes = await clanshopService.getProductDetail(product_id);
                // console.log('productDetailsRes',productDetailsRes);
                if (productDetailsRes?.status === 1) {
                    setProductDetails(productDetailsRes?.data);

                }

                const influencerData = await clanshopService.getClanShopInfluencerMedia(shop_id, product_id);
                if (influencerData?.data?.status === 1) {
                    setInfluencerMedia(influencerData?.data?.rows);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        
    }, [shop_id, product_id, selectedSize, cartButton]);

    const getData = (size?: any) => {
        let keyFound = '';
        for (const key in ProductDetails?.product_data[0]) {
            if (ProductDetails?.product_data[0].hasOwnProperty(key)) {
                if (ProductDetails?.product_data[0][key] === size) {
                    keyFound = key;
                    break;
                }
            }
        }
        return keyFound ? keyFound.replace("property_", "") : ''

    }
    const handleProductClick = (product: any) => {
        navigate(
            `/product-details?product_id=${product?.cs_product_id}&shop_id=${product?.shop_id}`
        );
    };

    const handleBuyNow = () => {
        let itracker = 0.0;
        try{

        
        if (selectedSize === "") {
            setChooseSize("Select Size First")
        } else {
            itracker = 1.0;
            setChooseSize("")
            // console.log('selectedSize',selectedSize)
            let product:any = {
             size:selectedSize,
             shop_id:shop_id,
             quantity:1,
             shop_name:shopDetails?.[0]?.shop_name,
             product_image_url:ProductDetails?.product_images[0].media
            }
            // product = {
            //     ...product,
            //     ProductDetails[0]
            // }
            // console.log('ProductDetails',ProductDetails)
            // product.alet
            // console.log('setShopDetails',shopDetails)
            // console.log('setShopProducts',shopProducts)
            // ProductDetails[0]['size'] = selectedSize||''
            // ProductDetails[0]['shop_id'] = shop_id
            // ProductDetails[0]['quantity'] = 1
            // ProductDetails[0]['shop_name'] = shopDetails?.[0]?.shop_name
            itracker = 2.0;
            if (cartButton === 'Go to Cart') {
                navigate('/bag');
                // console.log('hello world')
                itracker = 3.0;
                // setCartButton
            } else {
                itracker = 4.0;
                addToCart({ ...product, ...ProductDetails.product_data[0] });
                // console.log('ProductDetails', ProductDetails[0])
                setCartButton('Go to Cart');
                itracker = 5.0;
            }
        }
    }catch(err){
        console.log(`handleBuyNow ::: ERROR :: ${itracker}`, err);
    }


        // navigate(`user-details?product_id=${product_id}&shop_id=${shop_id}`);
    };

    return (
        <div className='container'>
            <div className="ProductDetails-container">
                <div className="name-following">
                    <div className='image-name-followers'>
                        <img src='https://hips.hearstapps.com/hmg-prod/images/mh-9-22-wick-650dcf0aeb656.jpg?crop=0.447xw:0.895xh;0,0&resize=640:*' />
                        <span className='name-followers'>
                            <span className='name'>{shopDetails?.[0]?.shop_name}</span>
                            <span className='followers'>537K Followers</span>
                        </span>
                    </div>
                    <button className="btn following-btn">Following</button>
                </div>

                <Grid container spacing={2} className='product-details'>
                    <Grid item xs={12} md={6} className='product-details-left'>
                        {ProductDetails?.product_images?.map((data: any) => {
                            return (
                                <Grid xs={12} md={6}>
                                    <div className='product-image'>
                                        <img src={data?.media} alt="" />
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid xs={12} md={6} lg={6} className='product-details-right'>
                        <div className='product-name'>
                            <span>
                                <p className='name'>{ProductDetails?.product_data[0]?.product_name}</p>
                                <p className='category'>{ProductDetails?.product_data[0]?.product_description}</p>
                            </span>
                            <span className="material-symbols-outlined favorate-icon">
                                favorite
                            </span>
                        </div>
                        <div className='product-price'>
                            <p className='price'><span className='old-price' style={{ textDecoration: 'line-through' }}>&#8377;{ProductDetails?.product_data[0]?.price}</span>
                                <span className='new-price'>&#8377;{ProductDetails?.product_data[0]?.discounted_price}</span><span className='discount'>({ProductDetails?.product_data[0].discount}% off)</span>
                            </p>
                            <p className='mrp'>MRP inclusive of all taxes</p>
                        </div>
                        <div className='product-color mb-3'>
                            <div className='color-name'>
                                <span className='me-3'>COLOR</span>
                                <span>White</span>
                            </div>
                            <div className='color-boxes'>
                            {ProductDetails?.product_data[0][`value_${getData('colour')}`]?.split(",")?.map((colour: any, index:number) => {
                                        return (
                                            <>  
                                           
          <div
            key={index}
            // onClick={() => handleColorChange(color)}
            style={{
              backgroundColor: colour,
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              margin: '5px',
              cursor: 'pointer',
              border: selectedColor === colour ? '2px solid black' : 'none',
            }}      
            ></div>
                                    
                                            {/* <input
                                            type="radio"
                                            // id={key}
                                            style={{backgroundColor:`${colour}`}}
                                            value={colour}
                                            // checked={selectedValue === 'option3'}
                                            // onChange={handleRadioChange}
                                          />
                                          <span>{colour}</span> */}
                                          </>

                                )
                            })}
                            </div>
                        </div>
                        <div className='product-size'>
                            <p className='size'>
                                <span>SIZES</span>
                                <div className="flex space-x-4 my-4">
                                {ProductDetails?.product_data[0][`value_${getData('size')}`]?.split(",")?.map((size: any) => {
                                        return (
                                            <SizeButton
                                                key={size}
                                                size={size}
                                                selectedSize={selectedSize}
                                                onClick={handleButtonClick}
                                            />
                                        )
                                    })}
                                </div>
                                <span><StraightenIcon /><span style={{ textDecoration: 'underline' }}>Size Guide</span></span>
                            </p>

                        </div>
                        <button className='btn cart-button' onClick={handleBuyNow} style={{ width: '100%', borderRadius: '10px' }}>{cartButton}</button>
                        <span style={{ color: 'red' }}>{chooseSize}</span>
                        <div className='delivery-time mb-3' style={{ fontSize: '14px', fontWeight: '600' }}>Delivery Time:2-7 days</div>
                        <div className='description-fit'>
                            {/* <span>Description & Fit</span>
                            <span><KeyboardArrowDownIcon /></span> */}
                            <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Description & Fit
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className='materials'>
                            {/* <span>Materials</span>
                            <span><KeyboardArrowDownIcon /></span> */}
                            <Accordion style={{ boxShadow: 'none', border: 'none' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Materials
                                </AccordionSummary>
                                <AccordionDetails>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                    malesuada lacus ex, sit amet blandit leo lobortis eget.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <p className='return-policy' style={{ textDecoration: 'underline', fontSize: '13px', marginTop: '40px' }}>Return and Cancellation Policy</p>
                    </Grid>
                </Grid>
            </div>
            <div className='ifram-container'>
                {influencerMedia?.map((link: any, index: any) => {
                    // console.log('influencerMedia', influencerMedia)
                    const instagramEmbedCode = generateInstagramEmbedCode(link?.media);
                    return <InstagramEmbed key={index} embedCode={instagramEmbedCode} />;
                })}

            </div>
            <div className='product-details-slider'>
                <ProductSlider title={`More from ${shopDetails?.[0]?.shop_name}`} data={shopProducts} onProductClick={handleProductClick} />
            </div>
            <div className='product-content'>
                <ProductContent title='More content for this product' data={shopProducts} onProductClick={handleProductClick} />
            </div>
        </div>)
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addToCart: (data: any) => dispatch(addToCart(data)),
    };
};

const mapStateToProps = (state: any) => {
    return {
        CartReducer: state.CartReducer,
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);

export { connectToRedux as ProductDetails };

// export default connect(null, { addToCart })(ProductDetails);
