import React, { useEffect, useState } from 'react'
import { ClanShopService } from '../../core/services/clan.shop.service';
import { ProductCard } from '../product-card/product-card';
import HeadingStyle from '../../components/heading-style/heading-style';
import { useNavigate } from 'react-router';
import './all-shops.scss';

const clanshopService: ClanShopService = new ClanShopService();

const AllShops = () => {
    const [shopDetails, setShopDetails] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getShopDetails();
    }, []);

    async function getShopDetails() {
        try {
            const res = await clanshopService.getShopDetails("");
            console.log('res', res);
            if (res?.data?.status) {
                setShopDetails(res?.data?.rows);
            }
        } catch (error) {
            console.error('Error fetching getShopDetails:', error);
        }
    }

    const handleProductClick = (product: any) => {
        navigate(
            `/shop/${product?.shop_id}`
        );
    };

    return (
        <div className='container'>
            <div className='clanshop-user-interface'>
                <HeadingStyle
                    title='All Shops'
                    className='mb-3 d-block title'
                />
                <div className='container'>
                    <ProductCard
                        data={shopDetails}
                        onProductClick={handleProductClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default AllShops