// import { HashRouter } from "react-router-dom";
import React, { Fragment, useEffect, useState } from 'react';
import { AppLoader } from './components';
import {HeaderShop}  from './components/header/header-shop';
import { RoutesComponent } from './routes/route';
import { ToastContainer } from 'react-toastify';
// import './assets/styles/style.css';
// import './assets/styles/scss/global.scss';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { ProductDetails } from './pages/product-details/product-details';
import  MobileFooter  from './components/clan-shop/mobile-footer';

const App: React.FC = () => {
    return (
        <>
            <AppLoader />
            <HeaderShop />
            <ToastContainer
                autoClose={2000}
                style={{ width: '250px' }}
                position='top-right'
            />
            <div className='main-content-sec'>
                <RoutesComponent />
            </div>
            <MobileFooter />
        </>

    );
};

export default App;
