import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ClanShopService } from '../../core/services/clan.shop.service';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState, useEffect } from 'react';
import './shop.scss';
import { Products } from '../../components/clan-shop/products';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import GridViewIcon from '@mui/icons-material/GridView';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import { ProductSlider } from '../../components/clan-shop/product-slider';


const clanshopService: ClanShopService = new ClanShopService();

const Shop: React.FC = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 470);
    const [shopDetails, setShopDetails] = useState<any>([]);
    const navigate = useNavigate();
    const [value, setValue] = useState('0');
    const [shopProducts, setShopProducts] = useState<any>([]);
    const { shop_id } = useParams();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 470);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const shopDetailsRes = await clanshopService.getShopDetails(shop_id);
                if (shopDetailsRes?.data?.status === 1) {
                    setShopDetails(shopDetailsRes?.data?.rows);
                }

                const shopProductsRes = await clanshopService.getShopProducts(shop_id);
                if (shopProductsRes?.data?.status === 1) {
                    setShopProducts(shopProductsRes?.data?.rows);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleProductClick = (product: any) => {
        navigate(
            `/product-details?product_id=${product?.cs_product_id}&shop_id=${product?.shop_id}`
        );
    };

    return (
        <div className='container'>
            
                <div className='profile-div'>
                    <div className='profile-image' >
                        <img className='img' src='https://hips.hearstapps.com/hmg-prod/images/mh-9-22-wick-650dcf0aeb656.jpg?crop=0.447xw:0.895xh;0,0&resize=640:*'
                            alt='profile' />
                    </div>

                    <div className='profile-details'>
                        <span className='name-follow'>
                            <span className='name'>{shopDetails[0]?.shop_name}</span>
                            <button className='btn'>Follow</button>
                        </span>
                        <span className='followers mb-3'>7K Followers</span>
                        <span className='description'>{shopDetails[0]?.shop_description}</span>
                    </div>
                </div>
                
                <div className='mobile'>
                    <div className='mobile-profile-div'>
                        <div className='profile-image-details'>
                            <div className='profile-image' >
                                <img className='img' src='https://hips.hearstapps.com/hmg-prod/images/mh-9-22-wick-650dcf0aeb656.jpg?crop=0.447xw:0.895xh;0,0&resize=640:*'
                                    alt='profile' />
                            </div>
                            <div className='profile-details'>
                                <span className='name'>{shopDetails[0]?.shop_name}</span>
                                <span className='discribe'>hello there</span>
                                <span className='followers'>7K Followers</span>
                            </div>
                        </div>
                        <div className='description'>{shopDetails[0]?.shop_description}</div>
                        <button className='btn mobile-follow-btn'>Follow</button>
                    </div>
                </div>
            
            <div className='tabs'>
                <Box className='tabs-box'>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab className='tab' icon={<span style={{margin:'0px 10px'}} className="material-symbols-outlined">
                                    shoppingmode
                                </span>} label="PRODUCTS" value="1" />
                                <Tab className='tab' icon={<span style={{margin:'0px 10px'}} className="material-symbols-outlined">
                                    grid_view
                                </span>} label="COLLECTIONS" value="2" />
                                <Tab className='tab' icon={<span style={{margin:'0px 10px'}} className="material-symbols-outlined">
                                    star
                                </span>} label="FAVORITES" value="3" />
                            </TabList>
                        </Box>
                        {/* <TabPanel value="1">Item One</TabPanel>
                        <TabPanel value="2">Item Two</TabPanel>
                        <TabPanel value="3">Item Three</TabPanel> */}
                    </TabContext>
                </Box>
            </div>

            <div className='shop-slider'>
                <ProductSlider title='FEATURED PRODUCTS' data={shopProducts} onProductClick={handleProductClick} />
            </div>
            <div className='all-product'>
                <Products title='ALL PRODUCTS' data={shopProducts} onProductClick={handleProductClick} />
            </div>
            {/* <a onClick={handleProductDetails}>product details page</a> */}
        </div>
    )
}

export { Shop };