import {
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Checkbox,
    FormLabel,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { PublicService } from "../../core/services";
  import { FormValidation } from "../../core/utility";
  const publicService = new PublicService();
  const formValidation = new FormValidation();
  interface ErrorTypes {
      status: boolean;
      message: string;
  }
  
  type AddressTypes = {
      name:string;
    house_no: string;
    area: string;
    address_title: string;
    address_type: string;
    pincode: string;
    city: string;
    post_office: string;
    district: string;
    state: string;
    user_exsist: boolean;
    preferred_time:string
    landmark: string;
  };
  
  type errorsType = {
      error_name:ErrorTypes;
      error_email:ErrorTypes; 
      error_house_no: ErrorTypes;
      error_area: ErrorTypes;
      error_address_title: ErrorTypes;
      error_address_type: ErrorTypes;
      error_pincode: ErrorTypes;
      error_city: ErrorTypes;
      error_post_office: ErrorTypes;
  };
  
  
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  interface Props {
    //   handleChange: (data: any) => void;
    updateFormData: (data: AddressTypes) => void;
    formData:any;
    onInputChange:any;
  }
  
  const ClanshopAddAddress: React.FC<Props> = ({
    updateFormData,
    ...otherprops
  }) => {
    const [addressForm, setAddressForm] = useState<AddressTypes>({
      name:'',
      house_no: "",
      area: "",
      address_title: "",
      address_type: "",
      pincode: "",
      city: "",
      post_office: "",
      district: "",
      state: "",
      user_exsist: false,
      landmark: "",
      preferred_time:""
    });
  
    const [errorMessage, setErrorMesage] = useState<errorsType>({
          error_name:{status:false, message:''},
          error_email:{status:false, message:''},
          error_house_no:{status:false, message:''},
          error_area: {status:false, message:''},
          error_address_title:{status:false, message:''},
          error_address_type:{status:false, message:''},
          error_pincode: {status:false, message:''},
          error_city: {status:false, message:''},
          error_post_office:{status:false, message:''},
      });
    const [pincodeData, setPincodeData] = useState<any>([]);
  
    useEffect(() => {
      updateFormData(addressForm);
    }, [otherprops.formData.pincode]);
  
    const handlePincode = async (e: any) => {
        // setAddressForm({ ...addressForm, pincode: e.target.value });
        // setFormData.pincode(e.target.value);
        if (e.target.value.length === 6) {
            try {

                const response = await publicService.getPinCodeDetail(e.target.value);
                if (response.status === 200) {
                    const data = response?.data[0]?.PostOffice;
                    setPincodeData(data);
                    otherprops.formData.district = response?.data[0]?.PostOffice[0]?.District
                    otherprops.formData.state =  response?.data[0]?.PostOffice[0]?.State
                    otherprops.formData.pincode = e.target.value;
                    // otherprops.onInputChange('pincode', e.target.value);
                    // otherprops.onInputChange('pincode', otherprops.formData.district );
                    // otherprops.onInputChange('pincode', otherprops.formData.state);

                    // setAddressForm({
                    //     ...addressForm,
                    //     district: response?.data[0]?.PostOffice[0]?.District,
                    //     state: response?.data[0]?.PostOffice[0]?.State
                    // });
                    
                }
            } catch (err) {
                console.log('Data not found');
            }
        }
    };
    const handleChange = (name:any) => (e:any) => {
      setAddressForm((prev) => ({...prev, [name]: e.target.value}));
      const value = e.target.value; 
      switch (name) {
          case 'name':
            // Handle name change
            setAddressForm((prev) => ({ ...prev, name: value }));
            setErrorMesage((prev) => ({ ...prev, error_name:formValidation.GetNameValidate(value)}))
            break;
          case 'house_no':
            // Handle house number change
            setAddressForm((prev) => ({ ...prev, house_no: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_house_no: formValidation.GetClanShopAddressValid(`${value}`, "Flat/house no.")
          }));
            break;
          case 'area':
            
            setAddressForm((prev) => ({ ...prev, area: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_area: formValidation.GetClanShopAddressValid(`${value}`, "area")
          }));
            break;
            case 'address_title':
            
            setAddressForm((prev) => ({ ...prev, address_title: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_address_title: formValidation.GetClanShopAddressValid(
                  `${value}`,
                  "address title"
              )
          }));
            break;
            case 'address_type':
            
            setAddressForm((prev) => ({ ...prev, address_type: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_address_type: formValidation.GetClanShopAddressValid(
                  `${value}`,
                  "address type"
              )
          }));
            break;
            case 'pincode':
            
            setAddressForm((prev) => ({ ...prev, pincode: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_pincode: formValidation.GetPincodeValid(`${value}`)
          }));
            break;
            case 'city':
            
            setAddressForm((prev) => ({ ...prev, city: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_city: formValidation.GetClanShopAddressValid(`${value}`, "city")
          }));
            break;
            case 'post_office':
            
            setAddressForm((prev) => ({ ...prev, post_office: value }));
            setErrorMesage((prev: any) => ({
              ...prev,
              error_post_office: formValidation.GetClanShopAddressValid(`${value}`, "post office")
          }));
            break;
            case 'district':
            
            setAddressForm((prev) => ({ ...prev, district: value }));
            break;
            case 'state':
            
            setAddressForm((prev) => ({ ...prev, state: value }));
            break;
            case 'landmark':
            
            setAddressForm((prev) => ({ ...prev, landmark: value }));
            break;
            case 'preferred_time':
            
            setAddressForm((prev) => ({ ...prev, preferred_time: value }));
            break;
          // Add cases for other field names similarly
          default:
            // Default case, do nothing
            break;
        }
  
    }
  
    
  // const handleChange = (fieldName: string, method:any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      // 
      // const method = `get${fieldName}Validate`;
      // if (typeof formValidation[method] === 'function') {
      //   const validationResult = formValidation[method](e.target.value);
      //   // Handle validation result
      //   console.log(validationResult);
      // } else {
      //   console.error(`Validation method ${method} not found`);
      // }
  //   };
  // const handleChange = (name) => (e:any) => {
  //     const validatedName = formValidation.GetNameValidate(e.target.value);
  //                     console.log("Validated name:", validatedName); // Log the validated name for debugging
  //                     try {
  //                         handleChange('name', validatedName);
  //                     } catch (error) {
  //                         console.error("Error in handleOnBlur:", error); // Log any errors that occur
  //                     }
  
  // }
    return (
      <form>
      <div className="col-lg-12 mb-3">
                <TextField
                  size="small"
                  fullWidth
                  className="influencer_personal_info"
                  name="name"
                  type="text"
                  id="outlined-basic"
                  label="Full name"
                  variant="outlined"
                  value={otherprops.formData.name}
                  onChange={otherprops.onInputChange}
                  // onChange={(e) => handleOnBlur('name', formValidation.GetNameValidate(e.target.value))}
                  // onChange={(e) => handleChange('name')}
                  //     const validatedName = formValidation.GetNameValidate(e.target.value);
                  //     console.log("Validated name:", validatedName); // Log the validated name for debugging
                  //     try {
                  //         handleChange('name', validatedName);
                  //     } catch (error) {
                  //         console.error("Error in handleOnBlur:", error); // Log any errors that occur
                  //     }
                  // }
              // }
                  
                  // {(e) =>
                  //     setAddressForm((prev) => ({
                  //       ...prev,
                  //       name: e.target.value,
                  //     }))
                  //   }
                  //   onBlur={(e) => handleOnBlur('name', formValidation.GetNameValidate(e.target.value))}
                  // onChange={(e) => handleChange('name', e.target.value)}
          
                    
                />
                
                {/* <span className="error">{errorMessage?.error_name?.message}</span> */}
              </div>
        <div className="col-lg-12 mt-3">
          <TextField
            size="small"
            fullWidth
            className="influencer_personal_info"
            name="house_no"
            type="text"
            id="outlined-basic"
            label="Flat, House no., Building, Company, Apartment"
            variant="outlined"
            // onChange={(e) => handleChange('house_no')}
            value={otherprops.formData.house_no}
            onChange={otherprops.onInputChange}
          />
          {/* <span className='error'>
                      {errorMessage?.error_house_no.message}
                  </span> */}
        </div>
        <div className="col-lg-12 mt-3 mb-3">
          <TextField
            size="small"
            fullWidth
            className="influencer_personal_info"
            name="area"
            type="text"
            id="outlined-basic"
            label="Area, Street, Sector, Village"
            variant="outlined"
            value={otherprops.formData.area}
            onChange={otherprops.onInputChange}
            // onChange={(e) => handleChange('house_no')}
          />
          {/* <span className='error'>{errorMessage.error_area.message}</span> */}
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="address_title"
              type="text"
              id="outlined-basic"
              label="Address Title"
              variant="outlined"
              value={otherprops.formData.address_title}
            onChange={otherprops.onInputChange}
              // onChange={(e) => handleChange('house_no')}
            />
            {/* <span className='error'>
                          {errorMessage?.errormessage}
                      </span> */}
          </div>
          <div className="col-lg-6 mb-3">
            <FormControl size="small" className="w-100 input-style">
              <InputLabel id="demo-multiple-name-label">Address Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="input-style"
                id="demo-simple-select"
                label="Address Type"
                name="address_type"
                value={otherprops.formData.address_type}
                onChange={otherprops.onInputChange}
                // onChange={(e) => handleChange('house_no')}
              >
                <MenuItem value="Home">Home</MenuItem>
                <MenuItem value="Office">Office</MenuItem>
              </Select>
            </FormControl>
            {/* <span className='error'>
                          {errorAddressType?.message}
                      </span> */}
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="landmark"
              type="text"
              id="outlined-basic"
              label="Landmark"
              variant="outlined"
              value={otherprops.formData.landmark}
                onChange={otherprops.onInputChange}
              // onChange={(e) => handleChange('house_no')}
            />
            {/* <span className='error'>
                          {errorName?.message}
                      </span> */}
          </div>
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="pincode"
              type="text"
              id="outlined-basic"
              label="Pincode"
              variant="outlined"
              // value={otherprops.formData.pincode}
              onChange={(e:any) => handlePincode(e)}
              // value={addressForm.pincode}
              // onChange={handlePincode}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="state"
              type="text"
              id="outlined-basic"
              label="State"
              variant="outlined"
              value={otherprops.formData.state}
              onChange={otherprops.onInputChange}

              disabled
            />
            {/* <span className='error'>
                          {errorName?.message}
                      </span> */}
          </div>
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="city"
              type="text"
              id="outlined-basic"
              label="Town/City"
              variant="outlined"
              value={otherprops.formData.city}
              onChange={otherprops.onInputChange}
              // onChange={(e) => {
              //     setAddressForm((prev) => ({ ...prev, city: e.target.value }));
              //   }}
              //   disabled
            />
            {/* <span className='error'>
                          {errorCity?.message}
                      </span> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3">
            <TextField
              size="small"
              fullWidth
              className="influencer_personal_info"
              name="district"
              type="text"
              id="outlined-basic"
              label="District"
              variant="outlined"
              // value={addressForm.district}
              value={otherprops.formData.district}
              onChange={otherprops.onInputChange}
              disabled
            />
            {/* <span className='error'>
                          {errorName?.message}
                      </span> */}
          </div>
          <div className="col-lg-6 mb-3">
            <FormControl fullWidth size='small'>
                          <InputLabel id='demo-multiple-name-label'>
                              Post Office
                          </InputLabel>
                          <Select
                              className='custom_category'
                              labelId='demo-multiple-name-label'
                              id='demo-multiple-name'
                              name='post_office'
                              // multiple
                              // value={addressForm.post_office}
                              value={otherprops.formData.post_office}
                              onChange={otherprops.onInputChange}
                              // onChange={(e) => handleChange('house_no')}
                              input={
                                  <OutlinedInput label='Post Office' />
                              }
                          // MenuProps={MenuProps}
                          >
                              {pincodeData?.map((data: any) => {
                                  return (
                                      <MenuItem
                                          key={data.Name}
                                          value={data.Name}
                                      >
                                          {data.Name}
                                      </MenuItem>
                                  );
                              })}
                          </Select>
                      </FormControl>
            {/* <span className='error'>
                          {errorPostOffice?.message}
                      </span> */}
          </div>
        </div>
        <p>
          <Checkbox {...label} defaultChecked  value={otherprops.formData.default_address}
            onChange={otherprops.onInputChange}/> Make this
          your default address
        </p>
  
        {/* <p className="mt-3">Preffered Time for delivery </p> */}
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Preffered Time for delivery
          </FormLabel>
          <RadioGroup
            defaultValue="9:00 AM to 12:00 PM"
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="preferred_time"
            value={otherprops.formData.preferred_time}
            onChange={otherprops.onInputChange}
          >
            <FormControlLabel
              className="preferred-time-label"
              value="9:00 AM to 12:00 PM"
              control={<Radio />}
              label="9:00 AM to 12:00 PM"
            />
            <FormControlLabel
              className="preferred-time-label"
              value="12:00 PM to 4:00 PM"
              control={<Radio />}
              label="12:00 PM to 4:00 PM"
            />
            <FormControlLabel
              className="preferred-time-label"
              value="4:00 PM to 9:00 PM"
              control={<Radio />}
              label="4:00 PM to 9:00 PM"
            />
          </RadioGroup>
        </FormControl>
      </form>
    );
  };
  
  export default ClanshopAddAddress;
  