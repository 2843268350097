import React from 'react';
// import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import './loader.scss';
interface Props {
    LoadingReducer?: any
}

const AppLoader: React.FC<Props> = (props) => {
    return (
        <div>
            {props.LoadingReducer?.loading && (<div className='spinner-container'>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                {/* <div className="spinner-grow text-primary" role="status">
                </div>
                <span className="sr-only">Loading...</span> */}
            </div>
            )}
        </div>
    )
};

const mapStateToProps = (state: any) => {
    return {
        LoadingReducer: state.LoadingReducer,
    }
};

const connectNavBar = connect(mapStateToProps)(AppLoader);
export { connectNavBar as AppLoader };