import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  GetUploadedURL,
  DeleteUploadURL,
  AddUploadURL,
  UpdateUploadURL,
  ResponseType,
} from "custom-type";

const http = new HttpService();

export class AnalyticsService {
  async getUploadedUrl(data: GetUploadedURL): Promise<ResponseType> {
    const path = BASE_URL + "/get/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async deleteUploadURL(data: DeleteUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/delete/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async addUploadURL(data: AddUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/add/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async updateUploadURL(data: UpdateUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/update/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
}
