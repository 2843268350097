import Grid from '@mui/material/Grid';
import reel from '../../assets/images/Vector.png';

interface Props {
    title: string;
    data: any;
    onProductClick: (product: any) => void;
}
export const ProductContent: React.FC<Props> = ({ title, data, onProductClick, ...otherProps }) => {

    return (
        <div className='products'>
            <p className='title mt-3'>{title}</p>
            <Grid container rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                {data.map((product: any, index: number) => (
                    <Grid item xs={12} sm={6} md={6} lg={2.4}>
                        <div className='product'>
                            <div className='image-div' onClick={() => onProductClick(product)}>
                                <img className='image' src={product?.media_url} alt="" />
                            </div>
                            <div className='details mt-2'>
                                <div className='name-price'>
                                    <img className='img' src='https://hips.hearstapps.com/hmg-prod/images/mh-9-22-wick-650dcf0aeb656.jpg?crop=0.447xw:0.895xh;0,0&resize=640:*' />
                                    <p className='name'>{product?.product_name}</p>
                                </div>
                                <div className='icons'>
                                    <img src={reel} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
};