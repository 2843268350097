import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  ResponseType,
  UploadCreativeLinks,
  DeleteUploadedCreativeLinks,
  GetUploadedCreativeLinks,
} from "custom-type";

const http = new HttpService();

export class AddInfluencer {
  async addInfluencer(data: { campaignId: string | number, influencerData: any[] }) {
    const path = BASE_URL + `/brand/save/custom-add/influencers`;
    // const path = ''
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

}
