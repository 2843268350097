export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const LOGOUT = 'logout';
export const LOADING = 'loading';
export const LOGINUSERID = 'loginUserid';
export const ACCOUNTSWITCH = 'accountSwitch';
export const USERSWITCH = 'userSwitch';
export const CREATECAMPAIGN = 'createcampaign';
export const CAMPAIGNEDIT = 'campaignedit';
export const SELECTEDINFLUENCERCAMP = 'infcamp';
export const GUEST_USER = 'guest_user';
export const UPDATE_USER_ID = 'user_id';
export const USER_EMAIL_ID = 'user_email_id';
export const ADDTOCART = 'ADD_TO_CART';
export const REMOVEFROMCART = 'REMOVE_FROM_CART';
export const SET_CART = 'SET_CART';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const SUBSCRIPTIONDETAIL = 'subscription';
export const SET_USER_VERIFIED = 'SET_USER_VERIFIED';
export const SET_ACTIVE_STEP ='SET_ACTIVE_STEP';
