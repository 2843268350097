import {HttpService} from './https.service';
import {BASE_URL} from '../../config/config';
import {AddInfluencer, ResponseType, DeleteInfluencerInList} from 'custom-type';
const http = new HttpService();

export class ListService {
  async planList(data: any) {
    const path = BASE_URL + '/brand/plan/list';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async add_Campaign(data: any) {
    const path = BASE_URL + '/brand/planlist/add/campaign';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async campaign_list(data: any) {
    const path = BASE_URL + '/brand/campaign/list';

    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async plan_campaigns(data: any) {
    const path = BASE_URL + '/brand/plan/campaigns';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  static async influencer_campaign_List(data: any) {
    const path = BASE_URL + '/influencer/campaigns/list';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async get_applied_campaigns(data: any) {
    const path = BASE_URL + '/influencer/applied/campaigns';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  static async influencer_mycampaign_List(data: any) {
    const path = BASE_URL + '/influencer/mycampaigns/list';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async influencerShippingAddressList(influencerId: string | number) {
    const path =
      BASE_URL + `/influencer/shipping/address?influencerId=${influencerId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`influencerShippingAddressList :: ${error}`);
    }
  }

  static async brandShippingAddressList(data: any) {
    const path = BASE_URL + '/brand/shipping/address';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  //Note: Brand ==> Influencer List Section (Create List, Add Influencer, Delete Influencer, Delete List)
  async createInfluencerList(data: any) {
    const path = BASE_URL + '/brand/create/influencer/list';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async deleteInfluencerList(data: any) {
    const path = BASE_URL + '/brand/delete/influencer/list';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async renameList(data: any) {
    const path = BASE_URL + '/brand/edit/influencer/list/name';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async addInfluencerList(data: AddInfluencer): Promise<ResponseType> {
    const path = BASE_URL + '/brand/add/influencers';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async deleteInfluencerInList(
    data: DeleteInfluencerInList
  ): Promise<ResponseType> {
    const path = BASE_URL + '/brand/lists/delete/influencer';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
}
