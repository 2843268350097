// import { Logger } from "./Logger";
import {CryptoJS} from './cryptojs';

export class Methods {
  // logger = new Logger();
  crypto = new CryptoJS();

  formatDatetime(date: Date, format: string) {
    const _padStart = (value: number): string =>
      value.toString().padStart(2, '0');
    try {
      if (isNaN(date.getTime())) {
        throw new Error('Invalid Date provided');
      }

      return format
        .replace(/yyyy/g, _padStart(date.getFullYear()))
        .replace(/dd/g, _padStart(date.getDate()))
        .replace(/mm/g, _padStart(date.getMonth() + 1))
        .replace(/hh/g, _padStart(date.getHours()))
        .replace(/mi/g, _padStart(date.getMinutes()))
        .replace(/ss/g, _padStart(date.getSeconds()))
        .replace(/ms/g, _padStart(date.getMilliseconds()));
    } catch (error) {
      throw new Error('formatDatetime : ' + JSON.stringify(error));
    }
  }

  formatUTCDatetime(date: Date, format: string) {
    const _padStart = (value: number): string =>
      value.toString().padStart(2, '0');
    try {
      if (isNaN(date.getTime())) {
        throw new Error('Invalid Date provided');
      }

      return format
        .replace(/yyyy/g, _padStart(date.getUTCFullYear()))
        .replace(/dd/g, _padStart(date.getUTCDate()))
        .replace(/mm/g, _padStart(date.getUTCMonth() + 1))
        .replace(/hh/g, _padStart(date.getUTCHours()))
        .replace(/mi/g, _padStart(date.getUTCMinutes()))
        .replace(/ss/g, _padStart(date.getUTCSeconds()))
        .replace(/ms/g, _padStart(date.getUTCMilliseconds()));
    } catch (error) {
      throw new Error('formatUTCDatetime : ' + JSON.stringify(error));
    }
  }

  getDateTimeStamp_JS() {
    // 2020-05-12T16:23:16Z

    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatDatetime(new Date(), 'yyyy-mm-ddThh:mi:ssZ');
    } catch (error) {
      throw error;
    }
  }

  getDateTimeStamp() {
    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatDatetime(new Date(), 'yyyymmddhhmiss');
    } catch (error) {
      throw error;
    }
  }

  getDateMySQL(date: Date) {
    try {
      // Get year, month, and day components from the date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it's zero-based
      const day = String(date.getDate()).padStart(2, '0');

      // Create the 'YYYY-MM-DD' formatted string
      const formattedDate = `${year}-${month}-${day}`;
      // today = yyyy + '-' + mm + '-' + dd;
      // return this.formatDatetime(new Date(), "yyyy-mm-dd");
      return formattedDate;
    } catch (error) {
      throw error;
    }
  }

  getDate() {
    try {
      //today = yyyy + '' + mm + '' + dd
      return this.formatDatetime(new Date(), 'yyyymmdd');
    } catch (error) {
      throw error;
    }
  }

  getDTS() {
    try {
      //today = (yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss + '.' + ms);
      return this.formatDatetime(new Date(), 'yyyymmddhhmiss.ms');
    } catch (error) {
      throw error;
    }
  }

  getUTCDateTimeStamp() {
    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatUTCDatetime(new Date(), 'yyyymmddhhmiss');
    } catch (error) {
      throw error;
    }
  }

  getUTCDateMySQL() {
    try {
      // today = yyyy + '-' + mm + '-' + dd;
      return this.formatUTCDatetime(new Date(), 'yyyy-mm-dd');
    } catch (error) {
      throw error;
    }
  }

  getUTCDate() {
    try {
      //today = yyyy + '' + mm + '' + dd
      return this.formatUTCDatetime(new Date(), 'yyyymmdd');
    } catch (error) {
      throw error;
    }
  }

  getUTCDTS() {
    try {
      //today = (yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss + '.' + ms);
      return this.formatUTCDatetime(new Date(), 'yyyymmddhhmiss.ms');
    } catch (error) {
      throw error;
    }
  }

  getAuthKey() {
    let utcDate;
    let authKey = '';
    try {
      // today = yyyy + '-' + mm + '-' + dd;
      utcDate = this.formatUTCDatetime(new Date(), 'yyyy-mm-dd');
      authKey = this.crypto.MD5(utcDate);
      return authKey;
    } catch (error) {
      throw error;
    }
  }

  getFormattedUCString(name: string) {
    let result = '';
    try {
      if (name == '' || name == null) {
        return '';
      }

      name = name.toLowerCase().trim();
      const nameParts = name.split(' ');

      for (let i = 0; i < nameParts.length; i++) {
        result =
          result +
          ' ' +
          nameParts[i].charAt(0).toUpperCase() +
          nameParts[i].slice(1);
      }

      result = result.trim();
      return result;
    } catch (error) {
      throw error;
    }
  }

  async timeDelay(sec: number) {
    return new Promise((resolve) => setTimeout(resolve, sec * 1000));
  }

  decimalFormat(value: number | string) {
    try {
      //check number is Integer?
      if (typeof value === 'string') {
        value = +value;
      }

      let _isInteger = Number.isInteger(value);
      if (!_isInteger) {
        return value.toFixed(0);
      } else {
        return value;
      }
    } catch (err) {
      throw new Error(`decimalFormat Error :: ${err}`);
    }
  }

  convertTimeToHHMMSS(timeStr: string) {
    const [time, period] = timeStr.split(' ');
    const [hours, minutes] = time.split(':');

    let hh = parseInt(hours, 10);
    const mm = parseInt(minutes, 10);

    if (period === 'PM' && hh !== 12) {
      hh += 12;
    } else if (period === 'AM' && hh === 12) {
      hh = 0;
    }

    // Convert to HH:MM:SS format
    return `${hh.toString().padStart(2, '0')}:${mm
      .toString()
      .padStart(2, '0')}:00`;
  }

  calculateAge(birthDate: Date) {
    const birthDateObject = new Date(birthDate);
    const currentDate = new Date();

    const yearsDiff = currentDate.getFullYear() - birthDateObject.getFullYear();

    // Check if the birthday for this year has occurred
    if (
      currentDate.getMonth() < birthDateObject.getMonth() ||
      (currentDate.getMonth() === birthDateObject.getMonth() &&
        currentDate.getDate() < birthDateObject.getDate())
    ) {
      return yearsDiff - 1;
    } else {
      return yearsDiff;
    }
  }

  //Get all query Params
  _GetQueryParams(locations: any): Object {
    let queryParams: {[key: string]: any} = {};
    try {
      let getQuerys = new URLSearchParams(locations);
      getQuerys.forEach((v, k) => (queryParams[k] = v));

      return queryParams;
    } catch (error) {
      throw new Error(`_GetQueryParams method error : ${error}`);
    }
  }

  filter() {}

  formatNumber(number: number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    } else {
      return number.toString();
    }
  }

  formatNumberINR(number: number) {
    if (number >= 10000000) {
      return (number / 100000).toFixed(1) + 'Cr';
    } else if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'L';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    } else {
      return number.toString();
    }
  }
  calculateDaysDifference(
    startDate: Date | string,
    endDate: Date | string
  ): number {
    const startDateObj =
      typeof startDate === 'string' ? new Date(startDate) : startDate;
    const endDateObj =
      typeof endDate === 'string' ? new Date(endDate) : endDate;

    return Math.floor(
      (endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24)
    );
  }
}
