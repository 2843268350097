import * as React from 'react';
import './bag.scss';
import { useState, useEffect } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useLocation } from 'react-router-dom';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { connect } from 'react-redux';
import { removeFromCart } from '../../redux/action';
import { updateQuantity } from '../../redux/action';
import { Stepper } from '../../components/clan-shop/stepper'; 
import { useNavigate } from 'react-router';

const clanshopService = new ClanShopService();

interface Props {
    isVerified: boolean;
    CartReducer: any
    updateQuantity: (productId: number, quantity: number, shop_id: string, size: string) => void;
    removeFromCart: (product_id: number, product_size: string, product_shop_id: string) => void;
}




const Bag: React.FC<Props> = ({  CartReducer, isVerified, updateQuantity, removeFromCart}) => {

    console.log("CartReducer", CartReducer)
    const [size, setSize] = React.useState('');
    const [shopDetails, setShopDetails] = useState<any>([]);
    const [ProductDetails, setProductDetails] = useState<any>([]);
    const [quantity, setQuantity] = useState<number>(1);
    const [orderValue, setOrderValue] = useState<number>(0);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const shop_id = queryParams.get('shop_id');
    const product_id = queryParams.get('product_id');
    const getOrderValue = () => {
        try {
            const totalValue = CartReducer.reduce((total: any, item: any) => {
                const itemValue = item.quantity * item.discounted_price;
                return total + itemValue;
            }, 0);
            setOrderValue(totalValue);
        } catch (err) {
            console.log('err');
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const shopDetailsRes = await clanshopService.getShopDetails(shop_id);
                if (shopDetailsRes?.data?.status === 1) {
                    setShopDetails(shopDetailsRes?.data?.rows);
                }

                const productDetailsRes = await clanshopService.getProductDetail(product_id);
                if (productDetailsRes?.data?.status === 1) {
                    setProductDetails(productDetailsRes?.data?.rows);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        getOrderValue()
    }, [shop_id, product_id, CartReducer]);


    const handleQuantityChange = (product: any, quantity: any) => {
        console.log('handleQuantityChange 1234567')
        //     // setQuantity(quantity)
        // CartReducer.map((comp:any) => {
        //       if (comp.product_id === product.product_id && comp.shop_id === product.shop_id  && comp.size === product.size) {
        //         return { ...comp, quantity:[quantity] }
        //       }
        //       return comp;
        //     });

        updateQuantity(product.product_id, quantity, product.shop_id, product.size);
    };
    console.log(CartReducer);

    const handleRemoveFromCart = (product: any) => {
        removeFromCart(product.product_id, product.size, product.shop_id);
    }

    async function handleProceedToShipping() {
        if(isVerified){
            navigate(
                `/delivery-details`
            ); 
        }else{
            navigate(
                `/login`
            ); 
        }
        
    }

    return (
        <div className='container'>
        <div className="bag-container">
        <div className='stepper-box'>
            <Stepper activeStep={0}/>
            </div>
            <div className="bag-title">
                <h3 className="title">Shopping Bag</h3>
            </div>
            <div className='product-order'>
                <div className="bag-left">
                    {CartReducer?.map((product: any) => {
                        console.log("product", product)
                        return (
                            <div className='bag-card'>
                                <div className="image">
                                    <img src={product?.product_image_url} alt="" />
                                </div>
                                <div className="context">
                                    <div className='product-name'>
                                        <p>
                                            <p className='name'>{product?.product_name}</p>
                                            <p className='category'>{product?.category}</p>
                                        </p>
                                        <span className="material-symbols-outlined delete-icon" onClick={(e: any) => handleRemoveFromCart(product)}>
                                            delete
                                        </span>
                                    </div>
                                    <div className='product-price'>
                                        <p className='price'><span className='old-price' style={{ textDecoration: 'line-through' }}>&#8377;{product?.product_cost}</span>
                                            <span className='new-price'>&#8377;{product?.discounted_price}</span><span className='discount'>({product?.discount}% off)</span>
                                        </p>
                                    </div>
                                    <div className='title-value'>
                                        <div className='color-total'>
                                            <span className='color'>Color:</span>
                                            <span className='size'>Size:</span>
                                            <span className='total'>Total:</span>
                                            <span className='shop-name'>Shop Name:</span>
                                        </div>
                                        <div className='value'>
                                            <span className='color'>{product?.colour ? product?.colour : 'white'}</span>
                                            <span className='size'>{product?.size}</span>
                                            <span className='total'>&#8377;{product?.discounted_price * product?.quantity}</span>
                                            <span className='shop-name'>{product?.shop_name}</span>
                                        </div>
                                    </div>

                                    <div className='size-quantity'>
                                        {/* <div className='size'>
                                    
                                        </div> */}
                                        <div className='quantity'>
                                            <FormControl className='quantity-select' size="small">
                                                <InputLabel id="demo-select-small-label">Quantity</InputLabel>
                                                <Select
                                                    className='select'
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={product?.quantity}
                                                    label="Quantity"
                                                    onChange={(e: any) => handleQuantityChange(product, e.target.value)}
                                                >


                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='wishlist'>
                                            <button className='btn wishlist-button'>
                                                <FavoriteBorderIcon sx={{ fontSize: '20px', marginRight: '5px', color: '#1C1B1F' }} />
                                                <span>Move to Wishlist</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })}
                </div>
                <div className="bag-right">
                    <div className="order-details">
                        <h5 className='title'>Order Details</h5>
                        <div className="order-value">
                            <p className='order'>
                                <span>Order Value</span>
                                <span>&#8377;{orderValue}</span>
                            </p>
                            {/* <p className='order'>
                                <span>Discount</span>
                                <span>$100</span>
                            </p> */}
                            <p className='order'>
                                <span>Delivery</span>
                                <span>&#8377;100</span>
                            </p>
                        </div>
                        <p className='total'>
                            <span>Total</span>
                            <span>&#8377;{orderValue === 0 ? 0:(orderValue + 100)}</span>
                        </p>
                        {CartReducer.length > 0 ?
                            (
                                <>
                                    <button className='btn btn-primary' onClick={handleProceedToShipping}>Proceed to Shipping</button>
                                    <span className='return-policy'>Return and Cancellation Policy</span>
                                </>
                            ) : (<></>)

                        }

                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        CartReducer: state.CartReducer.items,
        isVerified: state.authReducer.isVerified,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        removeFromCart: (product_id: number, product_size: string, product_shop_id: string) => dispatch(removeFromCart(product_id, product_size, product_shop_id)),
        updateQuantity: (productId: number, quantity: number, shop_id: string, size: string) => dispatch(updateQuantity(productId, quantity, shop_id, size))
    };
};
const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(Bag);

export { connectToRedux as Bag };