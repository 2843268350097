import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';
import { ResponseType } from 'custom-type';
const http = new HttpService();

export class CommonService {


    async addBankAccountDetail(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/add/bank/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    async addUPIDetail(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/add/upi/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    // To fetch account's company trade details
    async getCompanyTradeDetails(params: any) {
        try {
            const path = BASE_URL + `/user/company-trade-details?accountId=${params}`;
            const response = await http.getRequest(path, null);
            return response;
        } catch (error) {
            throw new Error(`getAccountDetails :: ${error}`);
        }
    }

    async updateAccountDetail(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/update/account/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    async getSavedBankDetails(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/get/saved/bank/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    async getSavedVPADetails(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/get/saved/upi/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    async deleteBankDetails(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/delete/saved/bank/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };
    async deleteVPADetails(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/delete/saved/upi/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };


    async updateDefaultAccount(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/update/default/bank/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    //checking plan and connect account
    async isValidPlan(data: any):Promise<ResponseType> {
        const path = BASE_URL + '/update/default/bank/accounts/details';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

    /* Validate Query Parameters Key And return tokken */

    async isValidKey(data: {key: string}):Promise<ResponseType> {
        const path = BASE_URL + '/isvalid/influencer/key';
        try {
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`Create Plan Service :: ${error}`)
        }
    };

}