import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';
import { GetDiscoverInfluencer, ResponseType } from 'custom-type';
const http = new HttpService();

export class DiscoverService {
  async getDiscoverInfluencers(
    params1?: any,
    params2?: any,
    params3?: any
  ): Promise<any> {
    const path =
      BASE_URL +
      `/brand/discover/influencers?influencer_type=${params1}&platform_type=${params2}&page=${params3}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
  async compareInfluencers(data: any) {
    try {
      const path =
        BASE_URL +
        `/brand/compare/influencers`;
        const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Compare Influencer Result :: ${error}`);
    }
  }

  // Get influencer ig Profile Insight
  // async getProfileInsight(params1?: any): Promise<any> {
  //   try {
  //     const path = BASE_URL + `/brand/profile-insight?instagramID=${params1}`;
  //     const response = await http.getRequest(path, null);
  //     return response;
  //   } catch (error) {
  //     throw new Error(`Influencer Result insight:: ${error}`);
  //   }
  // }


  // Get influencer YT Profile Insight
  // async getProfileInsightYt(params1?: any): Promise<any> {
  //   try {
  //     const path = BASE_URL + `/brand/profile-insight-yt?sm_id=${params1}`;
  //     const response = await http.getRequest(path, null);
  //     return response;
  //   } catch (error) {
  //     throw new Error(`Influencer Result insight:: ${error}`);
  //   }
  // }


  // SEARCH DISCOVER INSTAGRAM
  async getSearchInfluencer(data: {platform_type?: number | string, value?: number | string, influencer_type?: number | string, page?: number | string, insert_history: boolean, user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string }): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/profile-search?platform_type=${data?.platform_type}&value=${data?.value}&influencer_type=${data?.influencer_type}&page=${data?.page}&insert_history=${data?.insert_history}&user_id=${data?.user_id}&account_id=${data?.account_id}&brand_id=${data?.brand_id}&influencer_id=${data?.influencer_id}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Result insight:: ${error}`);
    }
  }
  // Influencer Count
  async getInfluencerCount(params1?: any): Promise<any> {
    try {
      const path =
        BASE_URL + `/brand/total-influencers-count?platform_type=${params1}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Influencer Result insight:: ${error}`);
    }
  }

  //Curated Influencer Count
  async getTopCuratedInfluencerCount(params1?: any, params2?: any): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/discover/curated-influencers-count?infType=${params1}&categoryId=${params2}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Top Curated Influencer Count Error :: ${error}`);
    }
  }

  //Top Curate Influencer
  async getTopCuratedInfluencer(params1?: any, params2?: any, params3?: any, params4?: any): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/discover/curated-influencers?infType=${params1}&influencerType=${params2}&categoryId=${params3}&page=${params4}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Top Curated Result Error :: ${error}`);
    }
  }
  //Filtered Influencer Count
  async getFilteredInfluencerCount(data: any): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/discover/filtered-influencers-count`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Filtered Count Result Error :: ${error}`);
    }
  }

  //Filtered Influencer
  async getFilteredInfluencer(data: any): Promise<any> {
    try {
      const path =
        BASE_URL +
        `/brand/discover/filtered-influencers`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Filtered Result Error :: ${error}`);
    }
  }


  async getFilteredInfluencerYT(data: any) {
    try {
      const path =
        BASE_URL +
        `/brand/discover/youtube/filter`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Filtered Result Error :: ${error}`);
    }
  }

  async canViewProfile(data: {user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string, sm_id: number | string }){
    try {
      const path = BASE_URL + `/can_view_profile`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Filtered Result Error :: ${error}`);
    }
  }

  async canFilterSearch(data: {user_id: number | string, account_id: number | string, brand_id: number | string, influencer_id: number | string }){
    try {
      const path = BASE_URL + `/can_filter_search`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Filtered Result Error :: ${error}`);
    }
  }
}
