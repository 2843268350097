import { HttpService } from './https.service';
import { BASE_URL } from '../../config/config';
import { SM_API_BASE_URL } from '../../config/config';

const http = new HttpService();

export class UserService {
  // To fetch personal info from auth_influencers table
  async getInfluencerDetails(params: any) {
    try {
      const path = BASE_URL + `/user/influencer-details?influencerId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getInfluencerDetails :: ${error}`);
    }
  }

  // To fetch user-info from auth_users table
  async getUserDetails(params: any) {
    try {
      const path = BASE_URL + `/user/user-details?userId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getUserDetails :: ${error}`);
    }
  }

  // To update user-info details
  async updateUserInfo(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/user-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateUserInfo :: ${error}`);
    }
  }

  // To fetch influencer and brand account details
  async getAccountDetails(params: any) {
    try {
      const path = BASE_URL + `/user/account-details?accountId=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getAccountDetails :: ${error}`);
    }
  }

  // To update influencer and brand account name
  async updateAccountDetails(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/account-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateAccountDetails :: ${error}`);
    }
  }

  async addShippingaddresss(data: any) {
    const path = BASE_URL + `/setting/add/address`;
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async updateShippingaddresss(data: any) {
    const path = BASE_URL + `/setting/update/address`;
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async updateDefaultAddress(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/update/default/shipping/address';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Update Default Address :: ${error}`)
    }
  };

  static async getInstaProfileDetails(params: any, options: string) {
    const path =
      SM_API_BASE_URL +
      `/instagram/public_profile?instagram_handle_name=${params}`;
    const response = await http.getRequest(path, options);
    return response;
  }

  static async getYtProfileDetails(params: any, options: string) {
    const path =
      SM_API_BASE_URL + `/youtube/public_profile?channel_id=${params}`;
    const response = await http.getRequest(path, options);
    return response;
  }

  async updateInfluencerDetails(data: any): Promise<any> {
    try {
      const path = BASE_URL + '/user/influencer-details/update';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updateProfileDetails :: ${error}`);
    }
  }

  async updatePassword(data: any) {
    try {
      const path = BASE_URL + '/user/update-password';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updatePassword :: ${error}`);
    }
  }

  async updatePin(data: any) {
    try {
      const path = BASE_URL + '/user/update-pin';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`updatePin :: ${error}`);
    }
  }

  async forgetPasswordPin(data: any) {
    try {
      const path = BASE_URL + '/forget-password-pin';
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`forgetPasswordPin :: ${error}`);
    }
  }

  //User Allowed Notifications 
  async getAllowedNotifications(params: any) {
    try {
      const path = BASE_URL + `/setting/notifications?account_id=${params?.account_id}&user_id=${params?.user_id}&influencer_id=${params?.influencer_id}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`getNotificationDetails :: ${error}`);
    }
  }

  // Update User Notification
  async updateNotificationSetting(data: any) {
    try {
      const path = BASE_URL + `/setting/notifications/update`;
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`UpdateNotificationDetails :: ${error}`);
    }
  }


}
