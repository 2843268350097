import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import AllShops from "../pages/all-shops/all-shops";
// import ProductDetails from "../pages/product-details/product-details";
import { Shop } from "../pages/shop/shop";
// import { UserDetails } from "../pages/user-details/user-details";
import { PlaceOrder } from "../pages/place-order/place-order";
import OrderStatus from "../pages/order-status/order-status";
import { ProductDetails } from "../pages/product-details/product-details";
import {Cart} from "../pages/cart/cart";
import { Bag } from "../pages/bag/bag";
import { DeliveryDetails } from "../pages/delivery-details/delivery-details";
import { Payment } from "../pages/payment/payment";
import { Login } from "../components/clan-shop/login";
import { ThankYou } from "../pages/thank-you/thank-you";
import Search from "../components/clan-shop/search";
import { Addresses } from "../pages/addresses/addresses";
import { PersonalDetails } from "../pages/personal-details/personal-details";
import { ContactUs } from "../components/clan-shop/contact-us";
import { HelpCenter } from "../components/clan-shop/help-center";
interface Props {
  guest_user: any;
}

const RoutesComponent: React.FC<Props> = (props: Props) => {
  return (
    <Routes>
      <Route path='/' element={<AllShops />} />
      <Route path='/shop/:shop_id' element={<Shop />} />
      <Route path='/product-details' Component={ProductDetails} />
      {/* <Route path='/product-details/user-details' element={<UserDetails />} />
      <Route path='/product-details/user-details/place-order' element={<PlaceOrder />} />
      <Route path='/clanshop/product-details/place-order/order-status' element={<OrderStatus />} /> */}
      <Route path='/bag' Component={Bag}/>
      <Route path='/delivery-details' Component={DeliveryDetails}/>
      <Route path='/login' Component={Login}/>
      <Route path='/thankyou' Component={ThankYou}/>
      <Route path='/search' Component={Search}/>
      <Route path='/addresses' Component={Addresses}/>
      <Route path='/personal-details' Component={PersonalDetails}/>
      <Route path='/contact-us' Component={ContactUs}/>
      <Route path='/help-center' Component={HelpCenter}/>
    </Routes>
  );
};

const mapStateToProps = (state: any) => {
  return {
    guest_user: state.guestUserReducer.guest_user,
  };
};

const connectToRedux = connect(mapStateToProps, null)(RoutesComponent);

export { connectToRedux as RoutesComponent };
