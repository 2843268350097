import React from 'react';

const HelpCenter: React.FC = () => {


    return (
        <div className='container'>
            <h2>Help Center</h2>
        </div>
    )
}

export { HelpCenter };