import {
    UserReducerState,
    LoadingReducerState,
    LoginUserIdReducerState,
    LoginUserId,
    CreateCampaign,
    AccountLayer,
    UserLayer,
    CampaignEditable,
    SelectedCampaign,
    GuestUser,
    SubscriptionDetail,
    UserVerified,
    Stepper
} from './user-reducer-state';
import {
    LOGINUSERID,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOADING,
    LOGOUT,
    ACCOUNTSWITCH,
    USERSWITCH,
    CREATECAMPAIGN,
    CAMPAIGNEDIT,
    SELECTEDINFLUENCERCAMP,
    GUEST_USER,
    UPDATE_USER_ID,
    USER_EMAIL_ID,
    ADDTOCART,
    REMOVEFROMCART,
    SET_CART,
    UPDATE_QUANTITY,
    UPDATE_TOTAL,
    SUBSCRIPTIONDETAIL,
    SET_USER_VERIFIED,
    SET_ACTIVE_STEP
} from '../types';

import { CartReducerState } from './cart-reducer-state';
const initialState: UserReducerState = {
    IsUserLogged: false,
    UserData: null,
    tiggerLogin: false,
    loading: false,
    loginPopup: false,
};

const loadingInitialState: LoadingReducerState = {
    loading: false,
};

const loginUserIdInitialState: LoginUserIdReducerState = {
    loginUserId: null,
};

const SwitchLayer1: AccountLayer = {
    switchAccount: null,
};

const SwitchLayer2: UserLayer = {
    switchUser: null,
};

const login_userID: LoginUserId = {
    userID: null,
};






export const createCampaignInitialState: CreateCampaign = {
    activeTab: 1,
    navigation: {
        campaign_create: false,
        createProduct: false,
        influencer: false,
        post: false,
    },
    hiddenTab: {
        campaign_create: false,
        createProduct: true,
        influencer: false,
        post: false,
    },
    completeStep: {
        createPlan: false,
        campaign_create: false,
        createProduct: false,
        influencer: false,
        post: false,
    },
    createPlan: null,
    createCampaign: null,
    product: null,
    influencer: null,
    post: null,
};

export const allowEditCampaign: CampaignEditable = {
    plan: true,
    campaign: true,
    product: true,
    influencer: true,
    post: true,
};

export const selectedCampaignInfInitialState: SelectedCampaign = {
    campaign: null,
    proposal: null,
    creatives: null,
    analytics: null,
};

export const guestUserInitialState: GuestUser = {
    guest_user: false, // Assuming initial value is false
    user_id: 0,
    user_email_id: '',
};

const subscription: SubscriptionDetail = {
    subscription_detail: null
}
//subscription Details
export const _SubscriptionDetail:any = (state:SubscriptionDetail = subscription, action:any) => {
    switch(action.type){
        case SUBSCRIPTIONDETAIL:
            return {subscription_detail: action.data};
        default: 
        return state;
    }
};


export const InfSelectCamp: any = (
    state: SelectedCampaign = selectedCampaignInfInitialState,
    action: any
) => {
    switch (action.type) {
        case SELECTEDINFLUENCERCAMP:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const EditCampaign: any = (
    state: CampaignEditable = allowEditCampaign,
    action: any
) => {
    switch (action.type) {
        case CAMPAIGNEDIT:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const CreateCampaignReducer: any = (
    state: CreateCampaign = createCampaignInitialState,
    action: any
) => {
    switch (action.type) {
        case CREATECAMPAIGN:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const SwitchLayer1Reducer: any = (
    state: AccountLayer = SwitchLayer1,
    action: any
) => {
    switch (action.type) {
        case ACCOUNTSWITCH:
            return { switchAccount: action.data };
        default:
            return state;
    }
};

export const SwitchLayer2Reducer: any = (
    state: UserLayer = SwitchLayer2,
    action: any
) => {
    switch (action.type) {
        case USERSWITCH:
            return { switchUser: action.data };
        default:
            return state;
    }
};

export const UserReducer: any = (
    state: UserReducerState = initialState,
    action: any
) => {
    switch (action.type) {
        case LOGIN_ERROR:
            return { ...state, IsUserLogged: false };
        case LOGIN_SUCCESS:
            return { ...state, UserData: action.data, IsUserLogged: true };
        case LOGOUT:
            return { ...state, UserData: null, IsUserLogged: false };
        case LOADING:
            return { ...state };
        default:
            return state;
    }
};

export const LoadingReducer: any = (
    loading: LoadingReducerState = loadingInitialState,
    action: any
) => {
    switch (action.type) {
        case LOADING:
            return { loading: action.data };
        default:
            return loading;
    }
};

export const LoginUserIdReducer: any = (
    loginUserId: LoginUserIdReducerState = loginUserIdInitialState,
    action: any
) => {
    switch (action.type) {
        case LOGINUSERID:
            return { loginUserId: action.data };
        default:
            return loginUserId;
    }
};

export const UserID: any = (
    loginuserid: LoginUserId = login_userID,
    action: any
) => {
    switch (action.type) {
        case LOGINUSERID:
            return { userID: action.data };
        default:
            return loginuserid;
    }
};

export const guestUserReducer: any = (
    state: GuestUser = guestUserInitialState,
    action: any
) => {
    switch (action.type) {
        case GUEST_USER:
            const newState = {
                ...state,
                guest_user: action.payload,
            };

            return newState;
        case UPDATE_USER_ID:
            const user_id = {
                ...state,
                user_id: action.payload,
            };
            return user_id;
        case USER_EMAIL_ID:
            const user_email_id = {
                ...state,
                user_email_id: action.payload,
            };
            return user_email_id;
        default:
            return state;
    }
};

// Function to load state from localStorage
const loadState = (key:string) => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };
  
  // Function to save state to localStorage
  const saveState = (key:string, state: any) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch {
      // Ignore write errors
    }
  };
  
  // Load initial state from localStorage
  const cartInitialState: CartReducerState =  loadState('cartState') || {
    items: [] // Initialize items array
  };

  const userVerified: UserVerified = loadState('user') || {
    isVerified: false,
    userToken:null
};

const stepper: Stepper = loadState('activeStep') || {
    step:0
};

  export const CartReducer = (state: CartReducerState = cartInitialState, action: any) => {
    let newState;
    console.log('action',action)
    switch(action.type) {
      case 'ADD_TO_CART':
        newState = {
          ...state,
          items: [...state.items, action.payload]
        };
        saveState('cartState',newState); // Save state to localStorage
        return newState;
      case 'REMOVE_FROM_CART':
        const { product_id, product_size, product_shop_id } = action.payload;
        newState = {
          ...state,
          items: state.items.filter((item:any) => !(item.product_id === product_id &&  item.shop_id === product_shop_id && item.size === product_size))
        };
        saveState('cartState',newState); // Save state to localStorage
        return newState;
        case 'UPDATE_QUANTITY':
            const { productId, quantity, size, shop_id } = action.payload;
            newState = {
                ...state,
                items: state.items.map((item:any) => 
                    (item.product_id === productId &&  item.shop_id === shop_id && item.size === size) ? { ...item, quantity } : item
                )
            };
            saveState('cartState',newState);
            return newState
      default:
        return state;
    }
  };

export const authReducer = (state: UserVerified = userVerified, action: any): UserVerified => {
    switch (action.type) {
        case SET_USER_VERIFIED:
            const newState = {
                ...state,
                isVerified: action.payload.isVerified,
                userToken: action.payload.userToken
            };
            saveState('user', newState);
            return newState;
        default:
            return state;
    }
};

export const stepReducer = (state: Stepper = stepper, action: any): Stepper => {
    switch (action.type) {
        case SET_ACTIVE_STEP:
            const newState = {
                ...state,
                step:action.payload
            };
            saveState('activeStep', newState);
            return newState;
        default:
            return state;
    }
};


