import { ClanShopService } from '../../core/services/clan.shop.service';
import { useLocation, useNavigate } from 'react-router-dom';
import './thank-you.scss'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const clanShopService = new ClanShopService();

export const ThankYou: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    const payment_id = searchParams.get('payment_id');
    const [orderDetails, setOrderDetails] = useState<any>([])

    useEffect(() => {
        getOrderDetails();
    }, []);

    async function getOrderDetails() {
        try {
            const res = await clanShopService.getOrderDetails(payment_id);
            console.log('res', res);
            if (res?.data?.rows) {
                setOrderDetails(res?.data?.rows);
            }
        } catch (error) {
            console.log('Error getOrderDetails:', error);
        }
    }
    return (
        <div className='container'>
            <div className="thank-you-container">
                <div className="title">
                    <h4>Order Confirmation Details</h4>
                </div>
                <div className='content'>
                    <div className='thank-you'>
                        <h1>Thank you for your purchase!</h1>
                        <p className='description'>Your order will be processed within 24 hours during working days.</p>
                        <div className='billing-address'>
                            <h5>Billing address</h5>
                            <div className='address'>
                                <div className='fields'>
                                    <div className='fields-div'><div className='field'>Name</div><span className='value'>{orderDetails[0]?.name}</span></div>
                                    <div className='fields-div'><div className='field'>Address</div><span className='value'>{orderDetails[0]?.house_no}, {orderDetails[0]?.area}<br /> {orderDetails[0]?.post_office}, {orderDetails[0]?.district}, <br /> {orderDetails[0]?.city}, {orderDetails[0]?.state}, <br /> pincode - {orderDetails[0]?.pincode}</span></div>
                                    <div className='fields-div'><div className='field'>Phone</div><span className='value'>{orderDetails[0]?.mobile}</span></div>
                                    <div className='fields-div'><div className='field'>Email</div>  <span className='value'>{orderDetails[0]?.email}</span></div>
                                </div>
                            </div>
                        </div>
                        <p className='tracking'>You will receive the tracking details on your email</p>
                    </div>
                    <div className='summary'>
                        <h4>Order Summary</h4>
                        <div className='order-statics'>
                            <div className='date'>
                                <p className='field'>Date</p>
                                <p className='value'>{orderDetails[0]?.created_at}</p>
                            </div>
                            <div className='order-number'>
                                <p className='field'>Order Number</p>
                                <p className='value'>{orderDetails[0]?.order_id}</p>
                            </div>
                            <div className='payment-method'>
                                <p className='field'>Payment Method</p>
                                <p className='value'>${orderDetails[0]?.method}</p>
                            </div>
                        </div>


                        {orderDetails?.map((order: any) => {
                            return (
                                <>
                                    <div className='product'>
                                        <div className='image-details'>
                                            <div className='image'>
                                                <img src="https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTEyL3JtNjA3LTNkYmQtc2NlbmUwNC1hLW1vY2t1cF8zLmpwZw.jpg" alt="" />
                                            </div>
                                            <div className='details'>
                                                <p className='name'>{order?.product_name}</p>
                                                <p className='pack'>Item Id : {order?.clanshop_order_id}</p>
                                                <p className='pack'>size: </p>
                                                <p className='quantity'>Qty: {order?.quantity}</p>
                                            </div>
                                        </div>
                                        <div className='price'>{order?.discounted_price * order?.quantity}</div>
                                    </div>
                                </>
                            )

                        })}
                        <div className='costs'>
                            <div className='cost'>
                                <span className='field'>Sub Total</span>
                                <span className='value'>{orderDetails?.reduce((sum: any, item: any) => sum + (item.discounted_price * item?.quantity), 0)}</span>
                            </div>
                            <div className='cost'>
                                <span className='field'>Shipping</span>
                                <span className='value'>100</span>
                            </div>
                            <div className='cost'>
                                <span className='field'>Tax</span>
                                <span className='value'>0.00</span>
                            </div>
                        </div>

                        <div className='total'>
                            <span>Order Total</span>
                            <span>{orderDetails[0]?.total_amount}</span>
                        </div>
                    </div>
                </div>
                <Link to='/'>
                    <button className='btn continue-shopping'>Continue Shopping</button>
                </Link>
            </div>
        </div>
    )
}