//LOCAL
// export const BASE_URL = 'http://localhost:4500';
// export const FACEBOOK_APP_ID: any = '152110156924040';
// export const GOOGLE_APP_ID: any =
//   '679675228906-53q8tu0dclkmjkagp9d31r882v7rk3ih.apps.googleusercontent.com';

// LIVE
export const BASE_URL = 'https://api.clanconnect.ai';
export const FACEBOOK_APP_ID: any = '586539722116753';
export const GOOGLE_APP_ID: any =
  '516197375270-5quads0lluld13ht2bdb3euto2qk6a64.apps.googleusercontent.com';

/* 
 //local Base URL
 export const FACEBOOK_APP_ID: any = '152110156924040'; //local facebook_app id
 export const FACEBOOK_APP_ID: any = "586539722116753";
 export const GOOGLE_APP_ID: any =
 '516197375270-5quads0lluld13ht2bdb3euto2qk6a64.apps.googleusercontent.com';
 "846728021163-umtm4qsds67q9disf83pmejgdijon24l.apps.googleusercontent.com";

 */

export const CAPTCHA_SITE_KEY = '6LelK2wnAAAAAKVBlc3DYgKUGcYbOfgWFp-bdITU';
//testing Key
export const PAYMENT_GATEWAY_KEY = 'rzp_test_DsjVVApo8X8BCB';

//SM api base url
export const SM_API_BASE_URL = 'https://smapi.clanconnect.ai';

//

//default Images
export const DefaultAccountLogo = {
  brand:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/brand_default.jpg',
  agency:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/agency_default.jpg',
  influencer:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/influencer_default.jpg',
  talent_partner:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/talent_partner_default.jpg',
};

export const DefaultUserProfile = {
  influencer_male_default:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/influencer_male_default.jpg',
  influencer_female_default:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/influencer_female_default.jpg',
  influencer_unknown_default:
    'https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/influencer_unknown_default.jpg',
};
