import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class SignUpService {
   async createInfluencerAccount(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/signup/influencer";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`createInfluencerAccount :: ${error}`);
    }
  }

   async createBrandAccount(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/signup/brand";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`create Brand Account API :: ${error}`);
    }
  }

   async createAgencyAccount(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/signup/agency";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error("createAgeyncyAccount :: " + error);
    }
  }

   async createTalentPartner(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/signup/talentpartner";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error("createAgeyncyAccount :: " + error);
    }
  }

  async addBrand(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/add/brand/agency";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error("createAgeyncyAccount :: " + error);
    }
  }

  async addInfluencer(data: any): Promise<any> {
    try {
      const path = BASE_URL + "/add/influencer/talentpartner";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error("createAgeyncyAccount :: " + error);
    }
  }

   async createInfluencerAccountWAFlow(data: any): Promise<any> {
    try {
      
      const path = BASE_URL + "/signup/influencer/wa";
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`createInfluencerAccountWA :: ${error}`);
    }
  }


  async manageUserAgency(data: any): Promise<any> {
    try 
    {
      const path = BASE_URL + "/manage-user";
      const response = await http.postRequest(path, data, null);
      return response;
    } 
    catch (error) {
      throw new Error(`manageUserAgency :: ${error}`);
    }
  }

}
