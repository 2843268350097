import React from 'react';
import { Button } from '@mui/material'
import './addresses.scss';

const Addresses: React.FC = () => {


    return (
        <div className='container'>
            <div className='clanshop-address-details'>
                <h3>Choose Address</h3>
                {/* {props.data?.map((data: any) => ( */}
                <div
                    className='address-card'
                ><h5>Hello addresses</h5>
                    <p className='name'>
                        <strong>Name : </strong>
                        shobhit chauhan
                    </p>
                    <p className='address'>
                        <strong>Address : </strong>
                        402, challera sector 44,
                        sector 16, noida,{' '}
                        Uttar Pradesh
                    </p>
                    <p className='pincode'>
                        <strong>pincode - </strong>
                        5435362
                    </p>
                    <Button
                        className='select-btn'
                    // key={}
                    // onClick={() =>
                    //   //  props.handleSelectedAddress(data.address_id)
                    // }
                    // variant={
                    //     // props.selectedAddress === data.address_id
                    //     //     ? 'contained'
                    //     //     : 'outlined'
                    // }
                    //   className={selectedButton === button.id ? "selected" : "unselected"}
                    >
                        select
                    </Button>
                </div>
                {/* ))} */}
            </div>
        </div>
    )
}

export { Addresses };