import React, { useEffect, useState } from 'react';
import './delivery-details.scss';
import { Login } from '../../components/clan-shop/login';
import ClanshopAddAddress from '../../components/clan-shop/address_component';
import { LocalService, PaymentGatewayService } from '../../core/services';
import ClanshopAddressDetails from '../../components/address-card/clanshop-address-details';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import useRazorpay, { RazorpayOptions } from 'react-razorpay';
import { ClanShopCreateOrder } from 'custom-type';
import { PAYMENT_GATEWAY_KEY } from '../../config/config';
import { toast } from 'react-toastify';
import { ClanShopService } from '../../core/services/clan.shop.service';
import { setUserVerified } from '../../redux/action';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stepper } from '../../components/clan-shop/stepper';
import { useNavigate } from 'react-router'; 


const localService: LocalService = new LocalService();
const paymentGatewayService: PaymentGatewayService = new PaymentGatewayService();
const clanShopService: ClanShopService = new ClanShopService();

interface Props {
    CartReducer: any;
    loading?: (data: any) => void;
    isVerified: boolean;
    userToken: any; // Define type for user details
    setUserVerified: (isVerified: boolean, userDetails?: any) => void;
}

const DeliveryDetails: React.FC<Props> = ({ CartReducer, setUserVerified, isVerified, userToken }) => {

    const [showotpBox, setShowOtpBox] = React.useState(false);
    const [Razorpay] = useRazorpay();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    // const [userVerified, setUserVerified] = React.useState(false);
    const [userData, setUserData] = React.useState<any>([]);
    const [selectedAddress, setSelectedAddress] = React.useState(0);
    const [addNewAddress, setAddNewAddress] = React.useState(false);
    const [orderValue, setOrderValue] = useState<number>(0);
    const [selectAddressError, setSelectAddressError] = useState('');
    const[addressId, setAddressId] = useState(0)
    const [addressForm, setAddressForm] = useState<any>({
        name:'',
        house_no: "",
        area: "",
        address_title: "",
        address_type: "",
        pincode: "",
        city: "",
        post_office: "",
        district: "",
        state: "",
        user_exsist: false,
        landmark: "",
        preferred_time:"",
        user_id:0
      });
      const handleChildInputChange = (e:any) => {
        const { name, value } = e.target;
        setAddressForm((prevData:any) => ({
          ...prevData,
          [name]: value
        }));
      };

      const handleSubmit = async() => {
        try{

        
        // console.log('Form Data:', addressForm);
        // You can now process the form data as needed
        if(addressForm.name &&  addressForm.pincode && addressForm.house_no && addressForm.post_office && addressForm.preferred_time){
            const data = addressForm;
            data['user_id'] = userToken;
            // console.log('user_id',userToken);
            // console.log('data', data);
            
            const addAddress = await clanShopService.addUserAddress(data);
            // console.log('addAddress',addAddress)
            if(addAddress?.data?.status?.status){
                // console.log('hello')
                setAddressId(addAddress?.data?.status?.address_id)
                return true;
            }else{
                return false;
            }
        }
    }catch(err){
           console.log('err', err) 
    }
      };
    async function createOrder(orderDetail: ClanShopCreateOrder) {
        try {
            const response: any =
                await paymentGatewayService.clanShopCreateOrder(orderDetail);
            return response;
        } catch (error) {
            throw new Error(`CreaterOrder`);
        }
    }
    const updateUserVerified = (newState: any, userData: any) => {
        if (userData.length > 0) {
            setUserVerified(newState, userData[0]?.user_id)
        } else {

        }
        // setUserData(userData)
    };

    const handleSelectedAddress = (address_id: any) => {
        // e.target.value = address_id
        setSelectedAddress(address_id);
        // console.log(address_id);
    };

    const handleAddNewAddress = () => {
        setAddNewAddress(true)
    }

    const getOrderValue = () => {
        try {
            const totalValue = CartReducer.reduce((total: any, item: any) => {
                const itemValue = item.quantity * item.discounted_price;
                return total + itemValue;
            }, 0);
            setOrderValue(totalValue);
        } catch (err) {
            console.log('err');
        }
    }

    React.useEffect(() => {
        getOrderValue()
        if (userToken) {
            getUserDetails();
        }

    }, [CartReducer, isVerified, userToken]);

    async function getUserDetails() {
        try {
            const userDataResponse = await clanShopService.getClanShopUserDetails(userToken);
            // console.log('userDataResponse', userDataResponse)
            if (userDataResponse.data?.status === 1) {
                setUserData(userDataResponse.data?.rows);
            }
        } catch (err) {
            console.log('getUserDetails', err);
        }
    }

    async function updateOrderDetail(payment_id: string) {
        try {
            const response =
                await paymentGatewayService.clanShopUpdateOrderDetail({
                    user_id: userData[0].user_id,
                    razorpay_payment_id: payment_id,
                    order_details: CartReducer,
                });
            if (response?.data?.status?.status) {
                toast.success(
                    `${response?.data?.status?.status}. Order Booked Successfully`
                );
                navigate(`/thankyou?payment_id=${payment_id}&status=true`);
               
                    // navigate(`/clanshop/product-details/place-order/order-status?payment_id=${payment_id}&status=${response?.data?.status?.status}`)
                


                // `/clanshop?shop_id=${
                //     shop_id
                //         ? shop_id
                //         : JSON.parse(
                //               props?.CartReducer?.cartList[0]?.shop_id
                //           )
                // }`
                // );
            }

        } catch (error) {
            throw new Error(`Update Order Detail Error :: ${error}`);
        }
        // finally {
        //   getSubscriptionDetail();
        // }
    }

    const handlePlaceOrder = async () => {
        let paymantStatus = {};
        setLoading(true);
        try {
            // if (selectedAddress === 0) {
            //     setSelectAddressError('Select Address First')
            //     handleSubmit();
            //     // const addAddress = await clanShopService.adduserAddress({})
            // } else {
                if(addressForm.name &&  addressForm.pincode && addressForm.house_no && addressForm.post_office && addressForm.preferred_time){
                const data = addressForm;
                data['user_id'] = userToken;
                // console.log('user_id',userToken);
                // console.log('data', data);
                let address_id
                const addAddress = await clanShopService.addUserAddress(data);
                // console.log('addAddress',addAddress)
                
            if(addAddress?.data?.status?.status){
                // setSelectAddressError('');
                // let data = addressForm;
                // data['user_id'] = userToken;
                // const addAddress = await clanShopService.addUserAddress(data);
                // console.log('hello',addAddress);
                //First Create order
                let _CreateOrder;
                address_id = addAddress?.data?.status?.address_id
                   _CreateOrder = await createOrder({
                    cart_details: CartReducer,
                    user_id: userToken,
                    address_id: address_id ? address_id:selectedAddress,
                    orderDetail: {
                        amount: (orderValue + 100) + '00',
                        currency: 'INR',
                        notes: {
                            key1: 'LS purchase',
                        },
                    },
                });
                // console.log('_CreateOrder',_CreateOrder);
                //if Order is Created the User Make a Payment
                if (_CreateOrder?.data?.status?.status) {
                    const RAZORPAY_OPTIONS: RazorpayOptions = {
                        key: PAYMENT_GATEWAY_KEY,
                        amount: _CreateOrder.data.rows.amount_due + '00',
                        currency: 'INR',
                        name: 'ClanConnect',
                        description: 'Test Transaction',
                        // image: Logo,
                        order_id: _CreateOrder.data.rows.id,
                        handler: function (response) {
                            updateOrderDetail(response.razorpay_payment_id);
                        },
                        prefill: {
                            name: userData[0].name,
                            email: userData[0].email,
                            contact: userData[0].mobile,
                        },
                        notes: {
                            address: 'Razorpay Corporate Office',
                        },
                        theme: {
                            color: '#00aae5',
                        },
                    };
                    const rzpay = new Razorpay(RAZORPAY_OPTIONS);
                    rzpay.open();
                } else {
                    localService.toastify(_CreateOrder?.data?.status?.message);
                }
            }
        }else{
            setSelectAddressError('please fill complete address')
        }

            // }
        } catch (error) {
            throw new Error(`Make Payment Error :: ${error}`);
        } finally {
            setLoading(false);
        }
    };
    // console.log(isVerified, userToken);


    return (
        <div className='container'>
                    <div className="bag-container">
                        <div className='stepper-box'>
                            <Stepper activeStep={1} />
                        </div>
                        <div className="bag-title">
                            <h3 className="title">Delivery Details</h3>
                        </div>
                        <div className='product-order'>
                            <div className="bag-left">
                                <div className='clanshop-user-details'>
                                    {(userData[0]?.address_id)
                                    // (userToken && (userData.length > 0)) 
                                    ?
                                        (
                                            <>
                                                {addNewAddress ?
                                                    (<ClanshopAddAddress updateFormData={() => { }} formData={addressForm} onInputChange={handleChildInputChange}/>)

                                                    :
                                                    (
                                                        <>
                                                            <ClanshopAddressDetails data={userData} selectedAddress={selectedAddress} handleSelectedAddress={handleSelectedAddress} />
                                                            <Button onClick={handleAddNewAddress}>Add New Address</Button>
                                                        </>
                                                    )

                                                }

                                            </>
                                        )
                                        :
                                        (<ClanshopAddAddress formData={addressForm} onInputChange={handleChildInputChange} updateFormData={() => { }} />)}

                                </div>
                            </div>
                            <div className="bag-right">
                                <div className="order-details">
                                    <h5 className='title'>Order Details</h5>
                                    <div className="order-value">
                                        <p className='order'>
                                            <span>Order Value</span>
                                            <span>&#8377;{orderValue}</span>
                                        </p>
                                        {/* <p className='order'>
                                        <span>Discount</span>
                                        <span>$100</span>
                                    </p> */}
                                        <p className='order'>
                                            <span>Delivery</span>
                                            <span>&#8377;100</span>
                                        </p>
                                    </div>
                                    <p className='total'>
                                        <span>Total</span>
                                        <span>&#8377;{orderValue + 100}</span>
                                    </p>
                                    <button className='btn btn-primary' onClick={handlePlaceOrder}>Proceed to Payment</button>
                                    <p style={{color:'red'}}>{selectAddressError}</p>
                                    <span className='return-policy'>Return and Cancellation Policy</span>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        CartReducer: state.CartReducer.items,
        isVerified: state.authReducer.isVerified,
        userToken: state.authReducer.userToken,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setUserVerified: (isVerified: boolean, userToken: number) => dispatch(setUserVerified(isVerified, userToken))
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryDetails);

export { connectToRedux as DeliveryDetails };