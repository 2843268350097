import ClanConnectLogo from '../../assets/images/clan_connect_logo.png';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import './header-shop.scss';
import { LocalService } from '../../core/services';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { IconButton, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';

const localService: LocalService = new LocalService();
interface Props {
    itemCount: number
}

const HeaderShop: React.FC<Props> = ({ itemCount }) => {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 957);
    const [userLogin, setUserLogin] = useState(true);
    const [accountClicked, setAccountClicked] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 957);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
    };

    return (
        <header className='site-header site-header-switch app-header_brand'>
            <div className='container header-container-clanshop'>
                <div className='app-header_brand_left_side'>
                    {isMobile && (
                        <>
                            <IconButton onClick={() => toggleDrawer(true)}>
                                <MenuIcon sx={{ fontWeight: '900' }} />
                            </IconButton>
                            <Drawer anchor="left" className='drawer' open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
                                <List>
                                    <div className='close-logo'>
                                        <span className="material-symbols-outlined close" onClick={() => toggleDrawer(false)}>
                                            close
                                        </span>
                                        <a className='menu-logo'>
                                            <Link to='/'><img src={ClanConnectLogo} alt='Clan Connect' /></Link>
                                        </a>
                                    </div>
                                    <div className='list-item'>
                                        {userLogin ? <>
                                            <ListItem button>
                                                <ListItemText className='list-item' primary="Account" onClick={()=>{setAccountClicked(!accountClicked)}}/>
                                            </ListItem>
                                            {accountClicked &&
                                                <div className='account-click-item'>
                                                    <ListItem button>
                                                        <ListItemText onClick={() => { window.location.href = '/personal-details' }} className='list-item account-item' primary="Personal Details" />
                                                    </ListItem>
                                                    <ListItem button>
                                                        <ListItemText onClick={() => { window.location.href = '/addresses' }} className='list-item account-item' primary="Addresses" />
                                                    </ListItem>
                                                </div>
                                            }
                                            <ListItem button>
                                                <ListItemText className='list-item' primary="My Orders" />
                                            </ListItem>
                                        </> : <>
                                            <ListItem button>
                                                <ListItemText onClick={() => { window.location.href = '/login' }} className='list-item' primary="Login" />
                                            </ListItem>
                                        </>
                                        }
                                        <ListItem button>
                                            <ListItemText className='list-item' primary="Returns" />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemText className='list-item' primary="Track Order" />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemText onClick={() => { window.location.href = '/help-center' }} className='list-item' primary="Help Center" />
                                        </ListItem>
                                        <ListItem button>
                                            <ListItemText onClick={() => { window.location.href = '/contact-us' }} className='list-item' primary="Contact Us" />
                                        </ListItem>
                                    </div>
                                </List>
                                <div className='affiliate-btn-div'>
                                    <button className='btn affiliate-btn'>Became an Affiliate</button>
                                </div>
                            </Drawer>
                        </>
                    )}
                    <a className='logo'>
                        <Link to='/'><img src={ClanConnectLogo} alt='Clan Connect' /></Link>
                    </a>
                    {isMobile ? null :
                        <>
                            <span className='discover'>
                                Home
                            </span>
                            <span className='shop'>
                                Discover
                            </span>
                            <input
                                type="text"
                                placeholder="Search t-shirts, shoes & more"
                                className='search-input'
                            />
                        </>
                    }
                </div>


                <div className='app-header_brand_right_side'>
                    {localService.getStorageItem('guest_user_login') ? (
                        <span className="guest_user_avatar">
                            <span className="guest_user_pic _header_profile_picture">
                                <img src="https://gt-clanconnect.s3.ap-south-1.amazonaws.com/default/influencer_unknown_default.jpg" alt="profile" className="_header_profile_img" />
                            </span>
                            <span className="email">{localService.getStorageItem('user_email_id')}</span>
                        </span>
                    ) : (
                        <Fragment>
                            <div className='login-icons'>
                                {isMobile ? null :
                                    <span className='btn Login'>
                                        Login
                                    </span>
                                }
                                <div className='favorate-cart-icon'>
                                    <span className="material-symbols-outlined favorate-icon">
                                        favorite
                                    </span>
                                    <Link to='bag'>
                                        <span className="material-symbols-outlined bag-icon">
                                            shopping_bag
                                        </span>
                                        {itemCount > 0 &&
                                            <span className='header-cart-count'>
                                                {itemCount}
                                            </span>
                                        }
                                    </Link>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = (state: any) => {
    return {
        itemCount: state.CartReducer.items.length  // Assuming items is an array in your cart state
    };
};
const connectToRedux = connect(
    mapStateToProps,
    null
)(HeaderShop);

export { connectToRedux as HeaderShop };
//   export default connect(mapStateToProps)(HeaderShop);
// export { HeaderShop }
