import { Button } from '@mui/material'
import React from 'react'
import './clanshop-address-details.scss'

interface Props {
    data: any;
    selectedAddress: any;
    handleSelectedAddress: (address_id: any) => void;
}

const ClanshopAddressDetails: React.FC<Props> = (props) => {
    return (
        <div className='clanshop-address-details'>
            <h3>Choose Address</h3>
            {props.data?.map((data: any) => (
                <div
                    className={
                        props.selectedAddress === data.address_id
                            ? 'selected'
                            : 'address-card'
                    }
                ><h5>{data.address_title}</h5>
                    <p className='name'>
                        <strong>Name : </strong>
                        {data?.user_name}
                    </p>
                    <p className='address'>
                        <strong>Address : </strong>
                        {data?.house_no}, {data?.area},{' '}
                        {data?.post_office}, {data?.city},{' '}
                        {data?.state}
                    </p>
                    <p className='pincode'>
                        <strong>pincode - </strong>
                        {data?.pincode}
                    </p>
                    <Button
                    className='select-btn'
                        key={data.address_id}
                        onClick={() =>
                            props.handleSelectedAddress(data.address_id)
                        }
                        variant={
                            props.selectedAddress === data.address_id
                                ? 'contained'
                                : 'outlined'
                        }
                    //   className={selectedButton === button.id ? "selected" : "unselected"}
                    >
                        select
                    </Button>
                </div>
            ))}
        </div>
    )
}

export default ClanshopAddressDetails