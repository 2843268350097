import {
    LOGIN_SUCCESS,
    LOGOUT,
    LOADING,
    LOGINUSERID,
    ACCOUNTSWITCH,
    USERSWITCH,
    CREATECAMPAIGN,
    CAMPAIGNEDIT,
    SELECTEDINFLUENCERCAMP,
    GUEST_USER,
    UPDATE_USER_ID,
    USER_EMAIL_ID,
    ADDTOCART,
    REMOVEFROMCART,
    SET_CART,
    UPDATE_QUANTITY,
    UPDATE_TOTAL,
    SUBSCRIPTIONDETAIL,
    SET_USER_VERIFIED,
    SET_ACTIVE_STEP
} from './types';

export const UserLogin = (data: any) => {
    if (data) {
        return {
            type: LOGIN_SUCCESS,
            data,
        };
    }
};

export const UserLogout = () => {
    return {
        type: LOGOUT,
    };
};

export const Loading = (data: any) => {
    return {
        type: LOADING,
        data,
    };
};

export const LoginUserId = (data: any) => {
    return {
        type: LOGINUSERID,
        data,
    };
};

export const SwitchLayer1 = (data: any) => {
    return {
        type: ACCOUNTSWITCH,
        data,
    };
};

export const SwitchLayer2 = (data: any) => {
    return {
        type: USERSWITCH,
        data,
    };
};

export const CreateCampaign = (data: any) => {
    return {
        type: CREATECAMPAIGN,
        data,
    };
};

export const EditCampaign = (data: any) => {
    return {
        type: CAMPAIGNEDIT,
        data,
    };
};

export const SelectedCampaign = (data: any) => {
    return {
        type: SELECTEDINFLUENCERCAMP,
        data,
    };
};

// setGuestUser
export const setGuestUser = (data: any) => {
    return {
        type: GUEST_USER,
        payload: data,
    };
};

export const setGuestUserId = (data: any) => {
    return {
        type: UPDATE_USER_ID,
        payload: data,
    };
};

export const setGuestUserEmail = (data: any) => {
    return {
        type: USER_EMAIL_ID,
        payload: data,
    };
};

// export const AddToCart = (data: any) => {
//     return {
//         type: ADDTOCART,
//         payload: data,
//     };
// };

// actions.ts
export const addToCart = (item: any) => {
    return {
      type: ADDTOCART,
      payload: item
    };
  };
  
  export const removeFromCart = (product_id:number, product_size:string, product_shop_id:string ) => {
    return {
      type: REMOVEFROMCART,
      payload:{
        product_id,
        product_size,
        product_shop_id 
      }
    };
  };
  
  export const updateQuantity = (productId:number, quantity:number, shop_id:string, size:string) => {
    console.log("data====>", productId, quantity, shop_id)
    return {
        type: UPDATE_QUANTITY,
        payload: {
            productId,
            quantity,
            shop_id,
            size
        }
    };
};

export const setUserVerified = (isVerified:Boolean, userToken:any) => {
    return {
        type: SET_USER_VERIFIED as typeof SET_USER_VERIFIED,
        payload: { isVerified, userToken }

    };
};

export const setActiveStep = (step:number) => {
    return{
        type:SET_ACTIVE_STEP,
        payload:step
    }
}
// export const RemoveFromCart = (data: any) => {
//     return {
//         type: REMOVEFROMCART,
//         payload: data,
//     };
// };

export const setCart = (data: any) => {
    return {
        type: SET_CART,
        payload: data,
    };
};

export const updateTotal = (total: any) => {
    return {
        type: UPDATE_TOTAL,
        payload: total,
    };
};



export const SubscriptionDetail = (data:any) =>{
    return {
        type: SUBSCRIPTIONDETAIL,
        data
    }
}