import { useState, useEffect, Fragment, useCallback } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
// import NotificationsIcon from "@mui/icons-material/Notifications";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {
  LocalService,
  LoginService,
  SubscriptionService,
} from '../../core/services';
import ClanConnectLogo from '../../assets/images/clan_connect_logo.png';
import './header.scss';
import { DefaultUserProfile } from '../../config/config';
import { ShoppingCart } from '@mui/icons-material';
import { SubscriptionDetail, setCart, setGuestUser } from '../../redux/action';
import { useCart } from '../../hooks/useCart';
import { BrandHeaderMenu, InfluencerHeaderMenu } from '../../data/data';
import useBodyClass from '../../hooks/useBodyClass';
import { AnimatePresence, motion } from 'framer-motion';
// important upgrade influencer popup component
import UpgradeInfluencerPopUp from '../popup/upgrade-influencer-popup';

const subscriptionService: SubscriptionService = new SubscriptionService();

const localService = new LocalService();
const loginService = new LoginService();

interface Props {
  isLogin: any;
  UserReducer: any;
  guest_user: any;
  user_email_id: any;
  CartReducer: any;
  updateSubscriptionDetail?: (data: any) => void;
  getSubscriptionDetail: any;
  accounts: any;
  switchLayer2: any;
  switchLayer1: any;
}

function logoNavigate<Props>(props: Props) {
  // console.log(props);
}

const Header: React.FC<Props> = ({ accounts, ...props }: Props) => {
  useCart();
  const [toggles, setToggles] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [userData, setUserData] = useState<any>([]);
  const [remainingDays, setRemainingDays] = useState<null | string>(null);

  const body = document.body;
  const scrollUp = 'scroll-up';
  const scrollDown = 'scroll-down';
  const dispatch = useDispatch<any>();
  let lastScroll = 0;
  const user_id = localService.getStorageItem('user_id');
  const [cartData, setCartData] = useState<any>([]);
  const product_ids = localService.getStorageItem('product_id');
  const navigate = useNavigate();
  const location = useLocation();
  const isClanshopUrl = location.pathname.includes('/clanshop');
  const [isAnimating, setIsAnimating] = useState(false);
  const [isBasicUser, setBasicUser] = useState(false);

  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      body.classList.remove(scrollDown);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  });
  const user_email_id: any = localService.getStorageItem('user_email_id');
  const guest_user_login = localService.getStorageItem('guest_user_login');
  if (guest_user_login) {
    dispatch(setGuestUser(true));
  }

  // set upgrade influencer Modal variables and usestate
  const [openUpgradeInfluencerModal, setopenUpgradeInfluencerModal] = useState(false);

  const handleOpenUpgradeInfluencerModal = () => setopenUpgradeInfluencerModal(true);
  function handleCloseUpradeInfluencerModal() {
    setopenUpgradeInfluencerModal(false);
  }

  // useEffect(() => {}, [dispatch]);
  useEffect(() => {
    async function getLeftDays() {
      const { account_id, user_id, influencer_id } = props.UserReducer;
      try {
        const response =
          await subscriptionService.getSubscription_Remaining_Days({
            account_id: account_id,
            user_id: user_id,
            influencer_id: influencer_id
          });
        if (response.data.status.status) {
          //update subscription details
          if (props.updateSubscriptionDetail) {
            props.updateSubscriptionDetail(response.data.rows[0]);
          }
        } else {
          setBasicUser(true);
          if (props.updateSubscriptionDetail) {
            props.updateSubscriptionDetail(null);
          }
        }
      } catch (error) {
        console.log('Influencer, TP, Header Error :: ' + JSON.stringify(error));
      }
    }

    if (props.UserReducer) {
      getLeftDays();
    }
  }, [props.UserReducer, dispatch]);
  const toggle = () => {
    setToggles((prevState) => !prevState);
  };
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  const handleNavigation = () => {
    navigate('/brand/settings/subscription');
  };
  const BrandMenu = props?.UserReducer?.view_admin_dashboard === "True" ? BrandHeaderMenu.mainMenu : BrandHeaderMenu.mainMenu.filter((item: any) => item.id !== 5);
  // To Filter admin dashboard tab if permission not granted................
  const outsideClick = useCallback(() => {
    const handleOutsideClick = (event: any) => {
      const todoInputContainer = document.querySelector('._header_profile_picture');
      if (todoInputContainer && !todoInputContainer.contains(event.target)) {
        setProfileMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [setProfileMenu]);

  useEffect(() => {
    const cleanup = outsideClick();

    return () => {
      cleanup();
    };
  }, [outsideClick]);
  const getDashboardLink = () => {
    if (isClanshopUrl && !props.isLogin) {
      return '/clanshop'; // Redirect to clanshop if isClanshopUrl is present
    } else {
      return (
        props.UserReducer?.account_type === 'Brand'
          ? '/brand/dashboard'
          : props.UserReducer?.account_type === 'Influencer'
            ? '/influencer/dashboard'
            : '/'
      );
    }
  };
  const listVars = {
    initial: {
      scale: 0,
      width: '10px',
      height: '10px',
      x: '0px',
      y: 0,

    },
    animate: {
      scale: 1,
      y: 20,
      transformOrigin: 'right top',
      width: '220px',
      height: 'auto',
      transition: {
        duration: 0.4,
        ease: [0.45, 0, 0.55, 1],
        // staggerChildren: 0.09
      },

    },
    exit: {
      scale: 0,
      x: '0',
      y: 0,
      width: '10px',
      height: '10px',
      transformOrigin: 'right top',
      transition: {
        delay: 0.4,
        duration: 0.3,
        ease: [0.22, 0, 0.39, 1],
        // staggerChildren: 0.09
      },
    }
  }


  const menuLinkVars = {
    initial: {
      y: "220px",
      transition: {
        duration: 0.5,
        ease: [0.37, 0, 0.63, 0.63]
      },
    },
    open: {
      y: 0,
      transition: {
        duration: 0.7,
        ease: [0, 0.55, 0.45, 1]
      },
    }
  };
  // const containerVars = {
  //   initial: {
  //     transition: {
  //       staggerChildren: 0.09,
  //       staggerDirection: -1,
  //     },
  //   },
  //   open: {
  //     transition: {
  //       delayChildren: 0.3,
  //       staggerChildren: 0.09,
  //       staggerDirection: 1,
  //     }
  //   }
  // }
  const headerProfileToggle = () => {
    setProfileMenu(prev => !prev)
    setIsAnimating(isAnimating => !isAnimating)
  }

  const handleNavLinkClick = (menu: any) => {
    if (menu.title === 'Profile View' && isBasicUser) {
      handleOpenUpgradeInfluencerModal();
    }
  };

  return (
    <>
      {/* Upgrade influencer popup */}
      {openUpgradeInfluencerModal && (
        <UpgradeInfluencerPopUp
          openUpgradeInfluencerModal={openUpgradeInfluencerModal}
          onClose={() => handleCloseUpradeInfluencerModal()}
          popupMinWidth='630px'
        >
        </UpgradeInfluencerPopUp>
      )}
      {/* Header - Brand, Influencer, TP and Agency */}
      {
        !(location.pathname === '/switch/account' || location.pathname === '/switch/user') && (
          <header
            className={
              !props.isLogin ? 'site-header' : 'app-header_brand site-header'
            }
          >
            <div className='container _header-container'>
              <div className='app-header_brand_left_side'>
                <a
                  className='logo'
                  href={getDashboardLink()}
                >
                  <img src={ClanConnectLogo} alt='Clan Connect' />
                </a>
                <nav
                  className={`nav-menu ${toggles ? 'active_header' : 'diable_header'
                    }`}
                  onClick={() => setToggles(false)}
                >
                  <ul className='app_header_ul d-none d-lg-flex'>
                    {(props.UserReducer?.account_type === 'Brand' || props.UserReducer?.account_type === 'Agency') ? (
                      <>
                        {BrandMenu.map((menu: any) => {
                          return (
                            <li
                              key={menu?.id}
                              onClick={() => setProfileMenu(false)}
                            >
                              <NavLink className='header_links' to={menu?.url}>
                                {menu?.url === location.pathname &&
                                  <motion.span style={{
                                    position: "absolute",
                                    width: '100%', height: '1px',
                                    top: '100%',
                                    left: 0,
                                    background: "#000"
                                  }} layoutId="underline"></motion.span>
                                }
                                <span>{menu?.title}</span>
                              </NavLink>
                            </li>
                          );
                        })}
                        {props.UserReducer?.account_type === 'Agency' && <li><NavLink className='header_links' to="/agency/dashboard">
                          <span>Agency Dashboard</span>
                        </NavLink></li>}
                      </>
                    ) : (props.UserReducer?.account_type === 'Influencer' || props.UserReducer?.account_type === 'Talent Partner') ? (
                      <>
                        {InfluencerHeaderMenu.mainMenu.map((menu: any) => {
                          return (
                            <li
                              key={menu?.id}
                              onClick={() => setProfileMenu(false)}
                            >
                              <NavLink className='header_links position-relative' to={isBasicUser && menu.title == 'Profile View' ? 'javascript:void(0);' : menu?.url} onClick={() => handleNavLinkClick(menu)}>
                                {menu?.url === location.pathname &&
                                  <motion.span style={{
                                    position: "absolute",
                                    width: '100%', height: '1px',
                                    top: '100%',
                                    left: 0,
                                    background: "#000"
                                  }} layoutId="underline"></motion.span>
                                }
                                < span > {menu?.title}</span>
                              </NavLink>
                            </li>
                          );
                        })}
                        {props.UserReducer?.account_type === 'Talent Partner' && <li
                          onClick={() => {
                            setProfileMenu(false);
                          }}
                        >
                          <NavLink
                            className='header_links'
                            to='/talent-partner/dashboard'
                          >
                            <span>Talent Dashboard</span>
                          </NavLink>
                        </li>}
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>
              <AnimatePresence>
                <motion.div className='app-header-brand_right_side' >
                  {props.isLogin && (
                    <>
                      {props.UserReducer?.account_type === "Agency" && props.UserReducer?.role_name !== "Editor" && props.UserReducer?.role_name !== "Report" && (
                        <Fragment>
                          <ul className='header-premium'>
                            <li>
                              {props.UserReducer?.brand_name}
                            </li>
                          </ul>
                          <span className='brand_header_profile_picture '>
                            <img
                              src={
                                props.UserReducer?.logo_url ??
                                DefaultUserProfile.influencer_unknown_default
                              }
                              alt='pic'
                            />
                          </span>
                          <div className="vr mx-3" ></div>
                        </Fragment>
                      )}
                      {props.UserReducer?.account_type === "Talent Partner" && (
                        <Fragment>
                          <ul className='header-premium'>
                            <li>
                              {props.UserReducer?.name}
                            </li>
                          </ul>
                          <span className='brand_header_profile_picture'>
                            <img
                              src={
                                props.UserReducer?.logo ??
                                DefaultUserProfile.influencer_unknown_default
                              }
                              alt='pic'
                            />
                          </span>
                          <div className="vr mx-3"></div>
                        </Fragment>
                      )}
                      <ul className='header-premium'>
                        {props.UserReducer?.account_type === "Talent Partner" && (
                          <li>{props.UserReducer?.account_name}</li>
                        )}
                        {props.UserReducer?.account_type === "Agency" && props.UserReducer?.role_name !== "Editor" && props.UserReducer?.role_name !== "Report" && (
                          <li>{props.UserReducer?.account_name}</li>
                        )}
                        <li>
                          {props.getSubscriptionDetail ? (
                            props.getSubscriptionDetail.remaining_days
                          ) : (
                            <Button
                              onClick={handleNavigation}
                              variant='contained'
                              className='btn btn-primary'
                            >
                              Go to Premium
                            </Button>
                          )}
                        </li>
                      </ul>
                      <span className='_header_profile_picture'>
                        <img
                          src={
                            props.UserReducer?.photo ??
                            DefaultUserProfile.influencer_unknown_default
                          }
                          alt='profile'
                          onClick={headerProfileToggle}

                        />
                        <ul
                          className='profile_image_toggle'
                          onClick={() => setProfileMenu(!profileMenu)}
                        >
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>

                      </span>

                      <AnimatePresence>
                        <motion.ul
                          className={`${profileMenu
                            ? 'profile_image_menu active'
                            : 'profile_image_menu'
                            }`}
                          variants={listVars}
                          initial="initial"
                          animate={profileMenu ? "animate" : "exit"}
                          exit="exit"
                        >
                          {props.UserReducer?.account_type === 'Brand' || props.UserReducer?.account_type === 'Agency' ? (
                            <>
                              {
                                BrandMenu.map((menu: any) => {
                                  return (
                                    <div key={menu?.id} className='overflow-hidden'>
                                      <motion.li
                                        variants={menuLinkVars}
                                        initial="initial"
                                        animate={profileMenu ? "open" : "initial"}
                                        key={menu?.id}
                                        onClick={() => setProfileMenu(false)}
                                      >
                                        <NavLink
                                          className='header_links'
                                          to={menu?.url}
                                        >
                                          <span>{menu?.title}</span>
                                        </NavLink>
                                      </motion.li>
                                    </div>
                                  );
                                })
                              }
                              {props.UserReducer?.account_type === 'Agency' && <li><NavLink className='header_links' to="/agency/dashboard">
                                <span>Agency Dashboard</span>
                              </NavLink></li>}
                            </>
                          ) : props.UserReducer?.account_type === 'Influencer' || props.UserReducer?.account_type === 'Talent Partner' ? (
                            <>
                              {InfluencerHeaderMenu.mainMenu.map((menu: any) => {
                                return (
                                  <div className='overflow-hidden'>
                                    <motion.li
                                      variants={menuLinkVars}
                                      initial="initial"
                                      animate={profileMenu ? "open" : "initial"}
                                      key={menu?.id}
                                      onClick={() => setProfileMenu(false)}
                                    >
                                      <NavLink
                                        className='header_links'
                                        to={isBasicUser && menu.title == 'Profile View' ? 'javascript:void(0);' : menu?.url}
                                        onClick={() => handleNavLinkClick(menu)}
                                      >
                                        <span>{menu?.title}</span>
                                      </NavLink>
                                    </motion.li>
                                  </div>
                                );
                              })}
                              {props.UserReducer?.account_type === 'Talent Partner' && (
                                <>
                                  <motion.li
                                    variants={menuLinkVars}
                                    initial="initial"
                                    animate={profileMenu ? "open" : "initial"}
                                    onClick={() => setProfileMenu(false)}
                                  >
                                    <NavLink
                                      className='header_links'
                                      to='/talent-partner/dashboard'
                                    >
                                      <span>Talent Dashboard</span>
                                    </NavLink>
                                  </motion.li>

                                </>
                              )
                              }
                            </>
                          ) : null}
                          <div className='overflow-hidden d-none'>
                            <motion.li
                              variants={menuLinkVars}
                              initial="initial"
                              animate={profileMenu ? "open" : "initial"}
                              onClick={() => setProfileMenu(false)}
                            >
                              {/* <MailIcon />  */}
                              <span>Inbox</span>
                            </motion.li>
                          </div>
                          <div className='overflow-hidden'>
                            <motion.li
                              variants={menuLinkVars}
                              initial="initial"
                              animate={profileMenu ? "open" : "initial"}
                              onClick={() => setProfileMenu(false)}
                            >
                              {/* <LiveHelpIcon />  */}
                              <NavLink
                                className='header_links'
                                to='https://web.clanconnect.ai/terms_conditions'
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span>FAQ</span>
                              </NavLink>
                            </motion.li>
                          </div>
                          {props.UserReducer?.account_type === 'Agency' && (
                            <div className='overflow-hidden'>
                              <motion.li
                                variants={menuLinkVars}
                                initial="initial"
                                animate={profileMenu ? "open" : "initial"}
                                onClick={() => {
                                  setProfileMenu(false);
                                  navigate('/switch/user');
                                }}
                              >
                                {' '}
                                {/* <SwitchAccountIcon />  */}
                                <span>Switch Brand</span>
                              </motion.li>
                            </div>
                          )}
                          {props.UserReducer?.account_type === 'Talent Partner' &&
                            <motion.li
                              variants={menuLinkVars}
                              initial="initial"
                              animate={profileMenu ? "open" : "initial"}

                              onClick={() => {
                                setProfileMenu(false);
                                navigate('/switch/user');
                              }}
                            >
                              {' '}
                              {/* <SwitchAccountIcon />  */}
                              <span>Switch Influencer</span>
                            </motion.li>}
                          {accounts?.length > 1 && (
                            <motion.li
                              variants={menuLinkVars}
                              initial="initial"
                              animate={profileMenu ? "open" : "initial"}

                              onClick={() => {
                                setProfileMenu(false);
                                navigate('/switch/account');
                              }}
                            >
                              {' '}
                              {/* <SwitchAccountIcon />  */}
                              <span>Switch Account</span>
                            </motion.li>
                          )}
                          <motion.li
                            variants={menuLinkVars}
                            initial="initial"
                            animate={profileMenu ? "open" : "initial"}
                            onClick={() => localService.logout()}
                          >
                            {/* <LogoutIcon />  */}
                            <span>Logout</span>
                          </motion.li>
                        </motion.ul>
                      </AnimatePresence>

                    </>
                  )}
                </motion.div>
              </AnimatePresence>
              {!props.isLogin && (
                <>
                  {props.guest_user ? (
                    <>
                      <span className='guest_user_avatar'>
                        <span className='guest_user_pic _header_profile_picture'>
                          <img
                            src={DefaultUserProfile.influencer_unknown_default}
                            alt='profile'
                            // onClick={() => setProfileMenu(!profileMenu)}
                            className='_header_profile_img'
                          />
                        </span>
                        <span className='email'>{JSON.parse(user_email_id)}</span>
                      </span>
                      <ul className='mobile_view_none'>
                        <li>
                          <NavLink to='/'>Sign Up</NavLink>
                        </li>
                        <li>
                          <NavLink to='/login'>Login</NavLink>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <nav
                        className={`nav-menu mx-auto ${toggles ? 'active_header' : ''
                          }`}
                        onClick={() => setToggles(false)}
                      >
                        <List
                          className='p-0 d-lg-none'
                          component='nav'
                          aria-label='mailbox folders'
                        >
                          <ListItemButton
                            divider
                            selected={selectedIndex === 1}
                            onClick={(event) => handleListItemClick(event, 1)}
                          >
                            <ListItemText>
                              <NavLink to='/'>Sign Up</NavLink>
                            </ListItemText>
                          </ListItemButton>
                          <ListItemButton
                            divider
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                          >
                            <ListItemText>
                              <NavLink to='/login'>Login</NavLink>
                            </ListItemText>
                          </ListItemButton>
                        </List>
                      </nav>
                      {!isClanshopUrl && (
                        <>
                          <Link
                            to='/request-a-demo'
                            className='btn btn-outline-primary btn-rounded ml-auto'
                          >
                            Request a demo
                          </Link>
                          <ul className='mobile_view_none'>
                            <li>
                              <NavLink to='/'>Sign Up</NavLink>
                            </li>
                            <li>
                              <NavLink to='/login'>Login</NavLink>
                            </li>
                          </ul>
                          <div
                            onClick={toggle}
                            className={
                              toggles
                                ? 'header-toggle-button toggle-active'
                                : 'header-toggle-button'
                            }
                          >
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {!props.isLogin && isClanshopUrl && props.CartReducer?.cartList?.length > 0 ? (
                <span className='header-cart'>
                  <Link to={`/clanshop/user/cart`}>
                    <ShoppingCart />
                    <span className='header-cart-count'>
                      {props.CartReducer?.cartList?.length}
                    </span>
                  </Link>
                </span>
              ) : null}
            </div>
          </header >
        )
      }
      {/* Switch Account and Switch User */}
      {
        location.pathname === '/switch/account' || location.pathname === '/switch/user' ? (
          <header className='site-header site-header-switch app-header_brand'>
            <div className='container _header-container'>
              <div className='app-header_brand_left_side'>
                <a className='logo'>
                  <img src={ClanConnectLogo} alt='Clan Connect' />
                </a>
                <nav
                  className={`nav-menu ${toggles ? 'active_header' : 'diable_header'
                    }`}
                  onClick={() => setToggles(false)}
                >
                  <ul className='app_header_ul d-none d-lg-flex '>
                    {props.switchLayer2?.account_type === 'Talent Partner' ? (
                      <>
                        <li
                          onClick={() => {
                            setProfileMenu(false);
                          }}
                        >
                          <NavLink
                            className='header_links'
                            to='/talent-partner/dashboard'
                          >
                            <span>Talent Dashboard</span>
                          </NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setProfileMenu(false);
                          }}
                        >
                          <NavLink
                            to='/switch/account'
                            className='header_links'
                          >
                            <span>Switch Account</span>
                          </NavLink>
                        </li>
                      </>
                    ) : props.switchLayer2?.account_type === 'Agency' ? (
                      <>
                        <li
                          onClick={() => {
                            setProfileMenu(false);
                          }}
                        >
                          <NavLink
                            className='header_links'
                            to='/agency/dashboard'
                          >
                            <span>Agency Dashboard</span>
                          </NavLink>
                        </li>
                        <li
                          onClick={() => {
                            setProfileMenu(false);
                          }}
                        >
                          <NavLink
                            to='/switch/account'
                            className='header_links'
                          >
                            <span>Switch Account</span>
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </nav>
              </div>

              <span
                onClick={() => localService.logout()}
                className='btn btn-outline-primary btn-rounded ml-auto'
              >
                Logout
              </span>
            </div>
          </header>
        ) : null
      }
    </>
  );
};
/*
    Connect all type of headers with redux
*/

// Get Redux State Data

const mapStateToProps = (state: any) => {
  return {
    isLogin: state.UserReducer.IsUserLogged,
    UserReducer: state.UserReducer.UserData,
    guest_user: state.guestUserReducer.guest_user,
    user_email_id: state.guestUserReducer.user_email_id,
    CartReducer: state.CartReducer,
    getSubscriptionDetail: state.Subscription.subscription_detail,
    accounts: state.SwitchLayer1Reducer.switchAccount,
    switchLayer2: state.SwitchLayer2Reducer.switchUser,
    switchLayer1: state.SwitchLayer1Reducer.switchAccount,
  };
};

// Update Redux State

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSubscriptionDetail: (data: any) => dispatch(SubscriptionDetail(data)),
  };
};

/*
    Export Individual
*/

// // Export Influencer Header
// const Influencer = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(InfluencerHeader);
// export { Influencer as InfluencerHeader };
// //Export Brand Header
// const Brand = connect(mapStateToProps, mapDispatchToProps)(BrandHeader);
// export { Brand as BrandHeader };

// Common Header
const Common = connect(mapStateToProps, mapDispatchToProps)(Header);
export { Common as Header };
