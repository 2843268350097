import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  ResponseType,
  UploadCreativeLinks,
  DeleteUploadedCreativeLinks,
  GetUploadedCreativeLinks,
} from "custom-type";

const http = new HttpService();

export class ApplyCampaignService {
  static async proposalDetail({campaignId, smId}:any) {
    const path = BASE_URL + `/influencer/proposal?campaignId=${campaignId}&smId=${smId}`;
    // const path = ''
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async applyCampaign(data: any) {
    const path = BASE_URL + "/influencer/proposal/apply";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async influencerList(data: any) {
    const path = BASE_URL + "/brand/negotiate/influencers";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async brandProposalDetails(data: any) {
    const path = BASE_URL + "/brand/proposal/details";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //

  static async proposalNegotiate(data: any) {
    const path = BASE_URL + "/proposal/negotiate";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async acceptProposal(data: any) {
    const path = BASE_URL + "/proposal/accept";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async rejectProposal(data: any) {
    const path = BASE_URL + "/proposal/reject";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async uploadCreative(data: any): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/creative/upload";
    try {
      const response = await http.postRequest(path, data, "file");
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async brandShippingReceiptUpload(data: any) {
    const path = BASE_URL + "/brand/barter/product/upload";
    try {
      const response = await http.postRequest(path, data, "file");
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //--------------------------------------------- Creative ------------------------------------------//

  async influencerCreativeDetail(data: any) {
    const path = BASE_URL + "/influencer/creative/detail";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getInfluencerCreativeComments(data: any) {
    const path = BASE_URL + "/influencer/creative/getcomments";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }
  //get Brand Comments After Accepted Proposal
  async getBrandCreativeComments(data: any) {
    const path = BASE_URL + "/brand/creative/getcomments";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //Send After Accepted Proposal
  async sendCreativeComments(data: any) {
    const path = BASE_URL + "/influencer/creative/comments";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  // Influencer Can upload live links (reels, story & shorts etc) creative when brand accept the creative
  async uploadCreativeLink(data: UploadCreativeLinks): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/creative/link/upload";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  };

  // Influencer can Delete Uploaded URL
  async deleteInfluencerCreativeLinks(data: DeleteUploadedCreativeLinks): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/creative/link/delete";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getInfluencerCreativeLinks(data: GetUploadedCreativeLinks): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/creative/link/get";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateDeliveryStatus(data: any) {
    const path = BASE_URL + "/influencer/product/delivery";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async creativeInfList(data: any) {
    const path = BASE_URL + "/brand/creative/influencerlist";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async getbrandCreatives(data: any) {
    const path = BASE_URL + "/brand/creativeslist";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async brandCreativeAcceptReject(data: any) {
    const path = BASE_URL + "/brand/creatives/action";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  static async brandCampaignStatus(data: any) {
    const path = BASE_URL + "/brand/campaign/status";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  async updateProposalShippingAdd(data: any) {
    const path = BASE_URL + "/influencer/update/proposal/address";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  };


  async deleteCampaign(data: {user_id: string | number, account_id: string | number, campaign_id: string| number}) {
    const path = BASE_URL + "/brand/delete/campaign";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  };

  async _IsValidGSTIN(data: {user_id: string | number, account_id: string | number}) {
    const path = BASE_URL + "/validate/gstin";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  };


  async _IsValidApplyProposal(data: {  smId: string | number, campaignID: string | number}) {
    const path = BASE_URL + `/isvalid/apply/campaign?campaignID=${data.campaignID}&smId=${data.smId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  };

  // monthly foc campaign allowed check with count of foc campaign.
  async allowed_monthly_foc_campaign(data: {user_id: string | number, account_id: string | number, brand_id: string | number, influencer_id: string | number}) {
    const path = BASE_URL + `/brand/allowed_monthly_foc_campaign?user_id=${data.user_id}&account_id=${data.account_id}&brand_id=${data.brand_id}&influencer_id=${data.influencer_id}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Error: allowed_monthly_foc_campaign :: ${error}`);
    }
  };

}
