import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { BreakPointTheme } from './hooks/useBreakPointTheme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_APP_ID } from './config/config';
import ScrollToTop from './components/scrollToTop';
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
const materialTheme = materialExtendTheme();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
            <BrowserRouter>
                <ScrollToTop />
                <ThemeProvider theme={BreakPointTheme}>
                    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                        <JoyCssVarsProvider>
                            <App />
                        </JoyCssVarsProvider>
                    </MaterialCssVarsProvider>
                </ThemeProvider>
            </BrowserRouter>
        </GoogleOAuthProvider>
    </Provider >
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
