import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HeadingStyle from '../heading-style/heading-style';

interface Props {
  children?: any;
  openUpgradeInfluencerModal: boolean;
  popupMinWidth?: any;
  popupMaxWidth?: any;
  onClose: () => void; // Receive onClose callback as a prop
}

const UpgradeInfluencerPopUp: React.FC<Props> = ({
  children,
  openUpgradeInfluencerModal,
  onClose,
  popupMinWidth,
  popupMaxWidth,
}) => {
  return (
    <Modal
      open={openUpgradeInfluencerModal}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='p-2'
    >
      <div className='popup_container'>
        <div className='popup_container_content'>
          <Box
            className='popup_content_container'
            sx={{
              minWidth: popupMinWidth,
              maxWidth: popupMaxWidth,
            }}
          >
            <HeadingStyle title='Upgrade to Premium:' />
            <IconButton
              edge='end'
              color='inherit'
              onClick={onClose} // Use the onClose callback to handle closing
              aria-label='close'
              sx={{ position: 'absolute', top: '8px', right: '15px' }}
            >
              <CloseIcon />
            </IconButton>
            <div className="">
              <ul>
                <li>Get discovered by Brands.</li>
                <li>Priority over unsubscribed influencers.</li>
                <li>Unlimited brand campaigns to apply on.</li>
                <li>Concise Analytics for your influencer profile.</li>
                <li>Work on Paid, Barter and CPV campaigns.</li>
                <li>Send Invoice effortlessly.</li>
              </ul>
              <div className="helper-text d-flex mt-3">
                <a className="d-inline ml-1" href="/influencer/settings/subscription">
                  Upgrade
                </a>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeInfluencerPopUp;