import React from 'react';

const PersonalDetails: React.FC = () => {


    return (
        <div className='container'>
            <h2>personal details</h2>
        </div>
    )
}

export { PersonalDetails };