import {
    Card,
    CardContent,
    CardMedia,
    Grid,
} from '@mui/material';
import defaultImage from '../../assets/images/image-fill.svg';
import './product-card.scss';

type ProductCard = {
    shop_id: number,
    account_id: number,
    shop_name: String,
    shop_description: String,
    created_at: String,
    updated_at: String
}

interface Props {
    data: ProductCard[];
    onProductClick: (shop: ProductCard) => void;
}

export const ProductCard: React.FC<Props> = ({ data, onProductClick, ...otherProps }) => {

    return (
        <div className='product-card-container'>
            <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3, md: 3 }}
            >
                {data.map((shop, index: number) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                        <Card className='card-container'>
                            <CardMedia
                                className='product-card-image'
                                sx={{ height: 140 }}
                                image={defaultImage}
                                onClick={() => onProductClick(shop)}
                                title='image'
                            />
                            <CardContent className='product-card-content'>
                                <h3>
                                    <span className="card-name-span"
                                        onClick={() => onProductClick(shop)}
                                    >
                                        {shop.shop_name}
                                    </span>
                                </h3>

                                <p className='product-card-desc'>
                                    {shop.shop_description}
                                </p>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

