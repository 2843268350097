import './stepper.scss';

interface Props {
    activeStep: number;
}

export const Stepper: React.FC<Props> = ({ activeStep }) => {
    return (

        <div className='stepper'>

            <span className={`bag ${activeStep === 0 ? 'active' : ''}`}>BAG</span>
            <div className='separater'></div>
            <span className={`delivery-details ${activeStep === 1 ? 'active' : ''}`}>DELIVERY DETAILS</span>
            <div className='separater'></div>
            <span className={`payment ${activeStep === 2 ? 'active' : ''}`}>PAYMENT</span>

        </div>
    );
}