import Grid from '@mui/material/Grid';
import reel from '../../assets/images/Vector.png';
import './products.scss';

type Product = {
    account_id: number;
    shop_id: number;
    shop_name: string;
    product_id: number;
    brand_id: number;
    brand_product_id: string;
    category: number;
    brand: string;
    product_name: string;
    product_description: string;
    product_cost: number;
    discount: number;
    total_commission: number;
    parameter_1: string;
    parameter_2: string;
    parameter_3: string;
    product_image_url: string;
    product_image_path: string;
    product_link: string;
    reference_link_1: string;
    reference_link_2: string;
    reference_link_3: string;
    inventory: number;
    status: string;
    inventory_headers: string;
    inventory_url: string;
    inventory_params: string;
    delivery_pincode: string;
    pincode_headers: string;
    pincode_params: string;
    pincode_url: string;
    payment_headers: string;
    payment_params: string;
    payment_url: string;
    place_order_headers: string;
    place_order_params: string;
    place_order_url: string;
    order_tracking_headers: string;
    order_tracking_params: string;
    order_tracking_url: string;
    product_integeration: string;
    created_at: string;
    updated_at: string;
    log: string;
    discounted_price: number;
}

interface Props {
    title: string;
    data: Product[];
    onProductClick: (product: Product) => void;
}

export const Products: React.FC<Props> = ({ title, data, onProductClick, ...otherProps }) => {

    return (
        <div className='products'>
            <p className='title mt-3'>{title}</p>
            <Grid container rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                {data.map((product: any, index: number) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                        <div className='product'>
                            <div className='image-div' onClick={() => onProductClick(product)}>
                                <img className='image' src={product?.media_url} alt="" />
                                {/* <img className='image' src={product?.product_image_url} alt="" /> */}
                                <span className="material-symbols-outlined favorate-icon">
                                    favorite
                                </span>
                            </div>
                            <div className='details mt-2'>
                                <div className='name-price'>
                                    <p className='name'><b>{product.brand_name}</b></p>
                                    <p className='name'>{product.product_name}</p>
                                    <p className='category'>{product.product_description}</p>
                                    <p className='price' style={{ textDecoration: 'line-through' }}>&#8377;{product?.price}</p>
                                    <p className='price'>&#8377;{product.discounted_price}<span style={{ color: 'green' }}>({product?.discount})%</span></p>
                                </div>
                                <div className='icons'>
                                    <img src={reel} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
};