import faqInlineImg from '../assets/images/faq-data-img.png'
import faqInlineImg1 from '../assets/images/clan-verified.svg'
import faqInlineImg2 from '../assets/images/faq-data-img-plus.png'

export const InfluencerHeaderMenu = {
  mainMenu: [
    {
      id: 1,
      title: "Dashboard",
      url: "/influencer/dashboard",
    },
    {
      id: 2,
      title: "My Campaigns",
      url: "/influencer/mycampaigns",
    },

    {
      id: 4,
      title: "Clan Shop",
      url: "/influencer/clanshop",
    },
    {
      id: 5,
      title: "Profile View",
      url: "/influencer/profile-view",
    },


    // Keep setting in the last
    {
      id: 3,
      title: "Settings",
      url: "/influencer/settings/userinfo",
    },

  ],
};


export const BrandHeaderMenu = {
  mainMenu: [
    {
      id: 1,
      title: "Dashboard",
      url: "/brand/dashboard",
    },
    {
      id: 2,
      title: "Discover",
      url: "/brand/discover",
    },
    {
      id: 3,
      title: "Campaigns",
      url: "/brand/plans",
    },

    {
      id: 8,
      title: "List",
      url: "/brand/list",
    },
    {
      id: 4,
      title: "Clan Shop",
      url: "/brand/clanshop",
    },
    {
      id: 5,
      title: "Admin Dashboard",
      url: "/brand/admin-dashboard",
    }, {
      id: 6,
      title: "Configurator",
      url: "/brand/configurator",
    },
    // Keep setting in last
    {
      id: 7,
      title: "Settings",
      url: "/brand/settings/userinfo",
    },


  ],
};

// export const ProfileSettingMenu = {
//   menu: [
//     {
//       id: 1,
//       title: "My Account",
//       url: "/brand/dashboard",
//     },
//     {
//       id: 2,
//       title: "User Info",
//       url: "/brand/discover",
//     },
//     {
//       id: 3,
//       title: "Security",
//       url: "/brand/plans",
//     },
//     {
//       id: 7,
//       title: "Social Connect",
//       url: "/brand/settings/userinfo",
//     },
//     {
//       id: 8,
//       title: "Notifications",
//       url: "/brand/list",
//     },
//     {
//       id: 4,
//       title: "Subscription",
//       url: "/subscription",
//     },
//     {
//       id: 5,
//       title: "Shipping Address",
//       url: "/brand/admin-dashboard/signup",
//     },{
//       id: 6,
//       title: "Payment Methods",
//       url: "/brand/configurator",
//     },{
//       id: 7,
//       title: "Company / Trade Details",
//       url: "/brand/configurator",
//     },{
//       id: 8,
//       title: "Invoice Settings",
//       url: "/brand/configurator",
//     },


//   ],
// };


// export const headerMenu = {
//   mainMenu: [
//     {
//       id: 1,
//       title: "Influencers",
//       url: "/influencers",
//     },
//     {
//       id: 2,
//       title: "Talent Partners",
//       url: "/talent-partners",
//     },
//     {
//       id: 3,
//       title: "Brands/Agencies",
//       url: "/brands-agencies",
//     },
//     {
//       id: 4,
//       title: "About Us",
//       url: "/about",
//     },

//     // {
//     //   id: 6,
//     //   title: "Case Studies",
//     //   url: "/case-studies",
//     // },
//     {
//       id: 5,
//       title: "Clan World",
//       url: "/clanworld",
//     },
//   ],
// };


export const faqData = [
  {
    brandsData: [
      {
        id: 1,
        faqTitle: "How to search for an Influencer?",
        img: faqInlineImg,
        faqSubtitleDescriptionList: [
          {
            id: 1,
            listDesc: "Step 1: Go to 'Search Influencer' in the left navigation panel."
          },
          {
            id: 2,
            listDesc: "Step 2: Either give a name, username for an influencer to search or click on filters to get a set of influencer results."
          },
          {
            id: 3,
            listDesc: "Step 3: Click on 'Show Results'."
          }
        ]
      },
      {
        id: 2,
        faqTitle: "How to connect with an Influencer?",
        faqDescription: "Every Influencer profile has 'Send Message' option. One can directly initiate conversation with the influencer by sending them a message."
      },
      {
        id: 3,
        faqTitle: "Can you help me in connecting with an Influencer who is not on Clanconnect?",
        faqDescription: "Our Business team get involved and connects you to the influencers who are not registered with us."
      },
      {
        id: 4,
        faqTitle: "What is a verified Influencer?",
        faqDescription: "A verified Influencer, on ClanConnect platform, is the one whose all details (includes their social channel, contact details etc.) are verified and genuine. "
      },
      {
        id: 5,
        faqTitle: "How to identify if the influencer is Clan registered?",
        content: <><p>The Clan registered influencer will have this symbol<img src={faqInlineImg1} alt="" width="16" height="16" style={{ margin: "0 5px" }} />with their profile photo</p></>,



      },
      {
        id: 6,
        faqTitle: "I see an influencer profile but is not Clan registered. Can I still reach out to him? If yes, how?",
        faqDescription: `Yes, you can still reach out to the influencers who are not registered with us. Our Business team helps in creating the contact between you and that influencer.`
      },
      {
        id: 7,
        faqTitle: "Which social platforms I can search influencers for?",
        faqDescription: `You can search the Influencers from Instagram and YouTube. Twitter will be coming next. `
      },
      {
        id: 8,
        faqTitle: "How do I create a list of selected influencers?",
        content: <><p>There are multiple ways to create a list<br />
          Method 1: Creating a list from filter search results. </p>
          <ul>
            <li>Step 1: The profile cards that appear after clicking 'Show Results' from filters have this symbol <img src={faqInlineImg2} alt="" width="16" height="16" /> Click this symbol. A dialog box will appear.</li>
            <li>Step 2: Give a list name to create a new list and add the selected influencer to that list.</li>
            <li>Step 3: Click 'Save'.</li>
            <li>You can view this list on My Lists page. </li>
          </ul><p>Method 2: Create a list from My Lists page.</p>
          <ul>
            <li>Step 1: Go to 'My Lists' page from left navigation panel.</li>
            <li>Step 2: Click on 'Create a new list' and give a name to that list. Select the platform for which you want to create that list. Click 'Save'.</li>
            <li>Step 3: Click on 'Add Influencers' to go to search page and apply filters or just select the influencers to add.</li>
            <li>
              Step 4: Click on 'Save at the right end corner to save the influencers in that list.
            </li>
          </ul></>
      },
      {
        id: 9,
        faqTitle: "Once I have posted the campaign brief, how do I see which influencers have applied to my campaign?",
        content: <><ul><li>Step 1: Go to 'Campaigns' from left navigation panel.</li>
          <li>Step 2: Click on the campaign for which you want to see the influencer proposals.</li>
          <li> Step 3: Go to 'Proposals' section of the campaign. All the proposals sent by influencers are visible here.</li>
        </ul></>
      },

      {
        id: 10,
        faqTitle: "How can I negotiate on the price quoted by the influencer for my paid campaign?",
        content: <><ul><li>Step 1: Click on the card under Proposals for which you want to negotiate.</li>
          <li>Step 2: Click on the 'Make a Counteroffer' option after opening that proposal.</li>
          <li> Step 3: A dialog box will appear where you can give the negotiated price and comments.</li>
        </ul><p>Please note that you can negotiate only if the influencer has agreed to negtiate while submitting the proposal. If not, the only available options will be Accept and Reject the proposal.</p></>
      },
      {
        id: 11,
        faqTitle: "Where can I check the creatives sent by influencers for approval?",
        content: <><ul><li>Step 1: Go to 'Campaigns' from left navigation panel.</li>
          <li>Step 2: Click on the campaign for which you want to see the influencer creatives.</li>
          <li> Step 3: Go to 'Creatives' section of the campaign.
            All the creatives sent by influencers are visible here.</li>
        </ul></>
      },
      {
        id: 12,
        faqTitle: "Can I give feedback on the creatives shared by influencers?",
        faqDescription: `Yes, you can share the feedback on the creatives shared by influencers. 

        Click on any creative and a section on the right side will open, where you can give your inputs on the creative.`
      },
      {
        id: 13,
        faqTitle: "Once the creative is approved, how can I check the schedule and details of the creative to be posted on influencer's social channel?",
        faqDescription: "To check the schedule and other deatils of the creative, click on 'Creatives' section on the campaign page. Under 'Approved' there is a section 'Scheduled'. All the creatives that are scheduled by the influencers will be visible here. Upon clicking any creative, 'Schedule' on the right panel shows all the details and time of the creative to go live."
      }, {
        id: 14,
        faqTitle: "How can I know if the creative is gone live?",
        faqDescription: `Go to 'Creatives' section of the campaign page. All creaties that are live will be listed under 'Live' section of 'Approved' tab.`
      }, {
        id: 15,
        faqTitle: "How can I check the performance of my campaign?",
        faqDescription: `Go to 'Analytics' section of the campaign page. The majority of the campaign performance data will be shown here. Detailed analytics can be viewed on going to the 'Detailed analytics' page.`
      },
      {
        id: 16,
        faqTitle: "Can I also check the performance even for one creative of the campaign?",
        faqDescription: `The 'Detailed analytics' page from the 'Analytics' section of campaign page will show all the detailed analytics related to even one single post of any influencer.`
      }, {
        id: 17,
        faqTitle: "I don't want to run influencer campaigns on my own. Does Clanconnect also help me with end to end campaign planning and execution?",
        faqDescription: `Yes, Clanconnect gives you an option to either plan and execute your influencer campaign using our sophisticated platform or let us take care of your end to end campaign requirements.`
      }
    ],
    influencerData: [
      {
        id: 1,
        faqTitle: "Does signing up with Clanconnect means I can't work with other agencies?",
        faqDescription: "Influencers are free to work with or sign up with other agencies even when they're onboarded on Clanconnect."
      }, {
        id: 2,
        faqTitle: "How do I apply to campaigns?",
        faqDescription: `Upon signing up, you can apply to the campaigns visible on your homepage (Browse Campaigns). Clicking on any campaign title takes you to the campaign page where you can view all the details for that campaign. You can either apply from the homepage or from the campaign details page.

        However, to apply, you first need to connect (if not connected already) your Instagram channel if you are an Instagram Influencer; or YouTube channel if you are a YouTube Influencer.`
      }, {
        id: 3,
        faqTitle: "Why do I need to connect my social channel to apply to the campaigns?",
        faqDescription: "The Brand would want to see your profile data insights to check if you are relevant to that campaign and shortlist you if you are a good fit. Only upon connecting your social channel, ClanConnect can access the data from your social profile and build a profile which can be viewed by Brands."
      }, {
        id: 4,
        faqTitle: "How secure is my data if I have connected my social channel?",
        faqDescription: "Your data is absolutely secure. The data is directly fetched from Instagram and YouTube and in no way the data can be misused. We recommend to connect your social media account sothat brands can view your profile analytics in order to shortlist you for the campaign."
      }, {
        id: 5,
        faqTitle: "Where can I see the campaigns to which I have applied?",
        faqDescription: "Go to 'My Campaigns from left navigation panel. All the campaigns that you have applied to or are working with, will be listed on this page."
      }, {
        id: 6,
        faqTitle: "Once the cost is approved, what should I do next?",
        faqDescription: "Once the cost is approved, you can send the creative for brand's feedback/ approval after receiving advance payments if any."
      }, {
        id: 7,
        faqTitle: "How do I submit the creatives for approval by the brand?",
        faqDescription: "Go to 'My Campaigns' from left navigation panel. Click on the campaign for which you want to send the creative. Go to 'Creatives' section of the campaign page. 'Upload' your creative to send to brands for their feedback/ approval."
      }, {
        id: 8,
        faqTitle: "Once approved, how can I schedule the creative on my channel?",
        faqDescription: "The 'Creatives' section on campaign page will show all the creatives you have sent to the brand, including the ones that are approved. Upon clicking any approved creative, you can schedule the creative in the 'Schedule' section of the right panel. "
      }, {
        id: 9,
        faqTitle: "Do I need approval by the brand before posting the creative on my social channels?",
        faqDescription: "Once the creative is scheduled, it goes to brand for their approval for posting time, caption etc. Once approved by the brand, the creative automatically gets scheduled to go live with the details given."
      },
      {
        id: 10,
        faqTitle: "Can I cancel my scheduled post? If yes, how?",
        faqDescription: "Once scheduled, the post can only be cancelled till 10 minutes prior to going live."
      },
      {
        id: 11,
        faqTitle: "When can I share an invoice?",
        faqDescription: "In case, you've asked for the advance payment you can share the invoice once your proposal is accepted. If you haven't asked for any advance payment then you can share the invoice after the creative goes live."
      },
      {
        id: 12,
        faqTitle: "How do I create an invoice?",
        faqDescription: "Go to 'Invoice' section of the campaign's page for which you want to create an invoice."
      },
      {
        id: 13,
        faqTitle: "Can I backout from a campaign after accepting it?",
        faqDescription: "When we send a campaign your way, we leave it up to your decretion to accept or reject it. But once you've accepted a campaign you will be liable to execute it fully."
      },
      {
        id: 14,
        faqTitle: "I've not been getting campaigns. What do I do?",
        faqDescription: "Please get in touch with us at influencer.support@clanconnect.ai and enlist yourself in our priority list so we don't miss out on sending you a relevant campaign."
      },
      {
        id: 15,
        faqTitle: "I am not able to apply to any campaign. What do I do?",
        faqDescription: "To apply on a campaign, you need to have a monthly or yearly subscription. Also, you need to connect the Instagram or YouTube as per the campaign requirements."
      },
      {
        id: 16,
        faqTitle: "I am not able to connect my Instagram account. How do I do it?",
        content: <><p>Please follow the steps to connect your Instagram account:</p><ul><li>You need to have a valid Facebook account</li>
          <li>The Facebook account must have a business page. You can create one under the 'Pages' section of Facebook.</li>
          <li> You need to have a business/professional Instagram account. If not, kindly update it to Business account under 'Settings {'>'} Account {'>'} Switch account type'.</li>
          <li>Connect your Facebook business page with Instagram account. To connect, go to Instagram 'Settings {'>'} Meta {'>'} Accounts Centre {'>'} Accounts.'</li>
          <li>Login to ClanConnect. Go to Settings {'>'} Social Connect. </li>
          <li>Click on Connect Facebook.</li>
          <li>Facebook screen will appear. Click on Conitnue as {'<your facebook name>'} or Edit Settings.</li>
        </ul></>,

      }
    ],

    instagramData: [



      {
        id: 4,
        faqTitle: "What is the campaign payment process?",
        faqDescription: "Once the Brand starts accepting the proposals from the Influencers, the payment shall be made to ClanConnect. This prepaid model ensures the security of the campaign. For different type of campaigns, please read the process below:",
        faqSubDescription: [
          {
            id: 1,
            faqSubtitle: "Paid Campaign",
            faqSubtitleDescription: 'The Brand can directly pay to influencers from the platform, once they have accepted the proposals as per the terms mentioned by the Influencers.'
          }, {
            id: 2,
            faqSubtitle: "Paid + Barter Campaign",
            faqSubtitleDescription: 'The Brand can directly pay to influencers from the platform, once they have accepted the proposals as per the terms mentioned by the Influencers.'
          },
          {
            id: 3,
            faqSubtitle: "Barter Campaign",
            faqSubtitleDescription: "The Brand needs to pay Rs. 200 per accepted Influencer proposal to ClanConnect. This payment can be made directly from the platform.",
          },
          {
            id: 4,
            faqSubtitle: 'CPV Campaign',
            faqSubtitleDescription: `Once the proposals are accepted by the Brand, the amount gets calculated as per the CPV model and the brand shall pay the complete amount directly to the influencers.`
          },
        ]
      },
      {
        id: 1,
        faqTitle: "How does a CPV campaign works?",
        faqDescription: "A CPV campaign works on Pay per View model. Once the proposal is accepted by the Brand, the influencer needs to get at least 50% (minimum half) of their follower number as views for the particular content to get qualified for the payout. The maximum payout shall be 200% (double) of their follower number as views, even if the influencer has received much higher views. The default number of days for the views to be counted is 14 days. "
      },
      {
        id: 2,
        faqTitle: "What are the advantages of CPV campaign?",
        faqSubDescription: [
          {
            id: 1,
            faqSubtitle: "Higher Cost efficiency",
            faqSubtitleDescription: 'The CPV model comes forward as a market disruptor where the influencer will get paid as per the view count; ie after analyzing direct benefits to the brand.'
          }, {
            id: 2,
            faqSubtitle: " Hyperlocal Reach",
            faqSubtitleDescription: 'Targeted local campaign with regional influencers'
          },
          {
            id: 3,
            faqSubtitle: "Lower CPV rates ",
            faqSubtitleDescription: "The influencers will clearly be communicated that payouts shall happen only on meeting min number of views as baseline, WITHOUT any paid promotion.",
          },
          {
            id: 4,
            faqSubtitle: 'Higher CPR efficiency',
            faqSubtitleDescription: "The cost per reach efficiency will simultaneously increase as the views increase, making the Reach cost efficient."
          }
        ]
      },



    ],
    youtubeData: [
      {
        id: 1,
        faqTitle: "What does CPM stand for and how do you calculate it?",
        faqDescription: "CPM stands for Cost Per Mille or Cost Per Thousand. CPM can be defined as the money an advertiser spends for one thousand views. Clan uses the CPM metric to calculate an approximate amount of money paid to an analysed YouTube influencer for the content they generate."
      },
      {
        id: 2,
        faqTitle: "How to generate a report on any YouTube channel?",
        faqDescription: `For a brand or an agency, the YouTube report of a certain channel/creator/influencer can be accessed by entering the channel name in the search bar and clicking on the desired account in the drop-down menu.
        
        An influencer/content creator can also see their channel’s analytics/report in their login homepage under the reports section. 
        `
      },
      {
        id: 3,
        faqTitle: "How frequently is the data updated for these YouTube reports?",
        faqDescription: `All data points barring the audience demography is updated daily. Audience demography is updated once a month.`
      },
      {
        id: 4,
        faqTitle: "What is a Channel Quality Score (CQS), and how is the CQS calculated?",
        faqDescription: ` Channel Quality score or the CQS is the effectiveness metric of any YouTube channel or an influencer. This can be used by brands/agencies to effectively plan the budgets for influencer marketing campaigns on YouTube.

        It is a metric that is learnt over time on a ML (machine learning) model for an influencer/YouTube channel. It is calculated on a range of 1-100, 100 being the highest benchmark. The following inputs determine the CQS: `,
        faqInnerDescription: {
          faqInnerDescriptionList: [
            {
              id: 1,
              listDesc: "The audience: It comprises the creator’s location, the alignment of the audience, age and gender distribution."
            },
            {
              id: 2,
              listDesc: "Credibility: Evaluates the audience’s legitimacy, the correlation between sudden spikes in views, drops in views and subscriber growth."
            },
            {
              id: 3,
              listDesc: "Engagement: component benchmarks the number of comments and reactions, views to comments ratio, upvotes to views ratio, and comments to views ratio with the similar influencers."
            },
            {
              id: 4,
              listDesc: "Creator’s influence: It is measured using the number of subscribers, stability of views, views to subscriber’s ratio, frequency of video, upvotes to downvotes ratio."
            },

          ]
        }
      },
      {
        id: 5,
        faqTitle: "How is Audience Language identified?",
        faqDescription: `It is arrived at by the language of the user content and text in their channel description.`
      }, {
        id: 6,
        faqTitle: "How is Audience Demography identified?",
        faqInnerDescription: {
          faqInnerDescriptionList: [
            {
              id: 1,
              listDesc: "For Geographical Location: This is identified using publicly available information on location from the profiles of the active audience of the influencer."
            },
            {
              id: 2,
              listDesc: "For Audience’s Age & Gender: We run through our image recognition technology over the influencer’s active audience to identify their age and the gender."
            }
          ]
        }
      },
      {
        id: 7,
        faqTitle: "What do you mean by the Similar Channels section?",
        faqDescription: `It is a recommendation given by the platform which understands the YouTube influencers/creators who in the past have made content similar to a given business/genre/category. For e.g. Recommending a tech influencer who has made engaging mobile unboxing videos to to a mobile manufacturing brand.

        It is a very effective way of planning a campaign and budgets, where a brand/agency can effectively plan an optimized campaign to deliver a certain KPI by reaching out to a content creator/creators who are more efficient but less expensive.
        
        Clan provides a list of similar channels by finding mutual qualities between influencers and their audiences considering the following attributes:`,
        faqInnerDescription: {
          faqInnerDescriptionList: [
            {
              id: 1,
              listDesc: "Audience Demographics: Factors like audience’s age, gender distribution and accounts having the same top-1 audience country. "
            },
            {
              id: 2,
              listDesc: "Influencer category and keywords that describe their content the best."
            }
          ]
        }
      },
      {
        id: 8,
        faqTitle: "What is meant by the term Video Frequency?",
        faqDescription: `The average number of uploads per week in a given time frame is called Video Frequency. It can be high or low depending on the upload frequency.

        This number is then compared by the number of views of similar accounts to give you a benchmark that assists in comprehending how an influencer has performed. `,
      },
      {
        id: 9,
        faqTitle: "What is meant by the term Audience Attitude?",
        faqDescription: `The ratio of Likes to dislikes, which helps in understanding whether an influencer’s video is being liked or disliked by their audience.

        The benchmark of the same is arrived at by comparing this metric with similar accounts to give you a benchmark that assists in understanding how the influencer’s audience reacts to their content. It can be a useful metric for planning if you are likely to consider/evaluate how a content will resonate with audiences after it has been posted.`
      },
      {
        id: 10,
        faqTitle: "What is 'Reaction Rate'?",
        faqDescription: `Likes and dislikes per 1000 views in a given time frame is referred to as the Reaction Rate.

        We compare this number with similar accounts by the number of views and give you a benchmark that helps to understand how engaged an influencer’s audience is. It can be a useful metric for planning if you are likely to consider/evaluate how the audience will engage when a content is posted by an influencer.`
      },
      {
        id: 11,
        faqTitle: "What is meant by Average engagement per video? How is it calculated?",
        faqDescription: `The median number of likes, dislikes, and comments per video in a timeframe indicates the Average engagement per video of that channel.

      Clan compares this number with the past numbers, for a better understanding of how an influencer is has performed in a given time frame.`
      },
      {
        id: 12,
        faqTitle: "Why is median calculated and not average of engagement metrics?",
        faqDescription: "The median unlike the average, does not consider a very large or too small engagement numbers but it looks at the middle of any data set divided in two halves which may give a better idea about engagement."
      },
      {
        id: 13,
        faqTitle: "What do you mean by Average views per video?",
        faqDescription: `It is the calculation of the median number of views on all videos in a given timeframe. However, we do not consider videos posted in the last week as the view numbers pertaining to a video may take a week to stabilize and doesn’t show any unusual spike or variation from the normal.

        The minimum view is the minimum number of views for a video in a given timeframe, and maximum view is the maximum number in a given timeframe. `
      },
      {
        id: 14,
        faqTitle: "What is meant by the term 'Subscribers Growth'?",
        faqDescription: `The number of people that have subscribed to a YouTube influencer’s account/channel in a given time frame reflects the Subscribers Growth. We compare this number with a previous period to help you understand how an influencer performs over time.

        The number of subscribers can also come out to be negative on certain days. Regular verification is done by YouTube to ensure the authenticity of the accounts and actions on all YouTube channels to make sure that metrics are free of spam, abuse, and closed accounts.`
      },
      {
        id: 15,
        faqTitle: "How are video views calculated?",
        faqDescription: `New views reflect how often an influencer’s videos has been viewed in a specific time frame and can be a significant measure of a video's overall popularity.

        For your better understanding of how an influencer performs over time, we compare this number with that of an earlier period.
        
        Owing to the fact that YouTube can discard low-quality playbacks, the number of views could be negative on a specific day.`
      },
      {
        id: 16,
        faqTitle: "What do you mean by Estimated Integration Price?",
        faqDescription: "The approximate price of one video with a brand mention posted by an influencer’s YouTube account is the Estimated Integration Price. Clan shows an estimated price that is calculated on the basis market research and intelligence. Please note that the actual price might be different."
      },
      {
        id: 17,
        faqTitle: "What is the Brand Mentions section and how to read it?",
        faqDescription: "Brand mention section can be found at the bottom of Each YouTube Report, only if an influencer has at least one mention over the past 90 days. The following could be included:",
        faqInnerDescription: {
          faqInnerDescriptionList: [
            {
              id: 1,
              listDesc: "Name of a brand and link to the brand website."
            },
            {
              id: 2,
              listDesc: "Total views. {If an influencer mentioned a brand in several videos, it is the sum of all views in those mentioned videos)"
            },
            {
              id: 3,
              listDesc: "CPM and its benchmark/performance. The benchmark is given basis the CPM of other videos of the influencer."
            },

          ],
          id: 0,
          desc: `Analysing brand mentions is an integral part of the growth strategy. Marketers can ascertain the collaborations which worked and vice-versa, basically understanding which videos did well in terms of the CPM. `
        }
      },
      {
        id: 18,
        faqTitle: "Why am I unable to check the audience demographic for a specific YouTube channel?",
        faqDescription: "It is possible that sometimes you might not see the audience demography. It is because of unavailability of sufficient reliable data to conduct the analysis.",
      },
      {
        id: 19,
        faqTitle: "What does the term EMV mean? How is it calculated?",
        faqDescription: "Earned Media Value or the EMV means/is reflective of the amount a brand would have spent to garner the same reach via paid social ads targeted at an audience with similar demographics. It is done by assigning a monetary value to every like, comment, and interaction made with a post or account on social media and adding it together to calculate the total value of their “earned” interactions. It's important to note that not all social media interactions are equal, but they all provide some value.",
        faqInnerDescription: {
          faqInnerDescriptionList: [
            {
              id: 1,
              listDesc: "Reach: Approximate number of impressions that every post deliver, the activity which could be termed as fraud or inauthentic is removed from this approximation."
            },
            {
              id: 2,
              listDesc: "Engagement: Likes, views and comments."
            },
            {
              id: 3,
              listDesc: "Follower count"
            },
            {
              id: 4,
              listDesc: "Account quality and authenticity"
            },
            {
              id: 5,
              listDesc: "Demographics: Audience’s age, sexual orientation, and Geographic location"
            },

          ],
          id: 0,
          desc: `The following metrics are calculated to arrive at the EMV:`
        }

      }






    ]
  }

];








