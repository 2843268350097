import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class SubscriptionService {
  async getPlanDetails(data: any) {
    const path = BASE_URL + "/get/subscription/plans/detail";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getSubscription_Remaining_Days(data: any) {
    const path = BASE_URL + "/get/subscription/days/left";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getSubscription_History(data: any) {
    const path = BASE_URL + "/get/subscription/history";
    const response = await http.postRequest(path, data, null);
    return response;
  }

  async getFocNewCampaignPlanDetail(data: any) {
    const path = BASE_URL + "/get/brand/foc/new_campaign";
    const response = await http.postRequest(path, data, null);
    return response;
  }
}
