import React from 'react';
import './search.scss';
const Search = () => {

    return (
        <div className='search-div'>
            <input
                type="text"
                placeholder="Search t-shirts, shoes & more"
                className='search-input'
            />
        </div>
    )
}

export default Search;